<template>
  <div class="page-container">
    <!--工具栏-->
    <div class="toolbar" style="float:left;padding-top:10px;padding-left:15px;">
      <el-form :inline="true" :model="filters" :size="size">
        <el-form-item>
          <el-input v-model="filters.meterNo" placeholder="仪表编号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="filters.contact" placeholder="户主姓名"></el-input>
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-search"
            label="查询"
            type="primary"
            @click="findPage(null)"
          />
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-file-excel-o"
            label="户用表EXCEL导入"
            type="warning"
            @click="handleImport"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--表格内容栏-->
    <base-table
      :data="pageResult"
      :columns="columns"
      :maxHeight="tableHeight"
      :showOperation="showOperation"
      @findPage="findPage"
    >
      <!--状态格式化 slot="formatStatus" 要与下列的表格prop 设置的值一致-->
      <template slot="formatStatus" slot-scope="scope">
        <el-tag :type="scope.row.status | statusFilter(2)">{{
          scope.row.status | statusFilter(1)
        }}</el-tag>
      </template>
      <template slot="optSolt" slot-scope="scope">
        <base-button
          icon="fa fa-search"
          label="查看"
          size="mini"
          type="primary"
          @click="handleView(scope.row.meterId)"
        />
        <base-button
          icon="fa fa-book"
          label="上传图片"
          size="mini"
          type="success"
          @click="handleUploadImg(scope.row)"
        />
      </template>
    </base-table>

    <el-dialog
      title="文件上传"
      width="40%"
      :visible.sync="importVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <file-import
        :importType="importType"
        :meterId="meterId"
        @close="importVisible = false"
        @reload="findPage(null)"
      />
    </el-dialog>
    <el-dialog
      title="户用表详细信息"
      width="70%"
      :visible.sync="dialogViewVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <household-meter-view
        :objectId="objectId"
        @close="dialogViewVisible = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable' // 树型列表
import BaseButton from '@/components/BaseButton' // 公共按钮
import FileImport from './FileImport'
import HouseholdMeterView from '@/views/gas/HouseholdMeterView'
export default {
  name: 'HouseholdMeter',
  components: {
    BaseTable,
    BaseButton,
    FileImport,
    HouseholdMeterView
  },
  data() {
    return {
      size: 'small', // 样式图标大小
      showOperation: false, // 不显示模板的操作状态栏
      dialogMenuPermsVisible: false, // 分配菜单权限窗口显示
      dialogDataPermsVisible: false, // 分配数据权限窗口显示
      tableHeight: window.innerHeight - 220, // 表格的高度
      dataScopeOptions: [], // 角色数据范围选择项
      filters: {
        meterNo: '',
        contact: ''
      },
      columns: [], // 初始表头信息
      pageRequest: { pageNum: 1, pageSize: 10 }, // 分页请示参数信息, 当前页,每页大小
      pageResult: {}, // 分页查询结果集
      meterId: null,
      importVisible: false, // 上传导入弹出框
      importType: 1, //文件上传类型，1-户用表导入 ，2-户用表图片上传
      dialogViewVisible: false,
      objectId: null // 对象主键ID,用户于修改时进行传递
    }
  },
  methods: {
    initLoad() {},
    /**
     * 分页查询数据
     * @param pageParams 分页请求参数
     */
    findPage: function(pageParams) {
      this.objectId = null
      if (pageParams !== null) {
        this.pageRequest = pageParams.pageRequest
      }
      this.pageRequest.meterNo = this.filters.meterNo
      this.pageRequest.contact = this.filters.contact
      this.$api.gas
        .findPage(this.pageRequest)
        .then(res => {
          if (res.code === 200) {
            this.pageResult = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .then(pageParams != null ? pageParams.callback : '')
    },
    /***
     * 初始化列表显示
     */
    initColumns: function() {
      this.columns = [
        { prop: 'meterNo', label: '仪表编号', sortable: false, minWidth: 100 },
        { prop: 'contact', label: '户主姓名', sortable: false, minWidth: 100 },
        { prop: 'phone', label: '联系电话', sortable: false, minWidth: 100 },
        { prop: 'idCard', label: '身份证号', sortable: false, minWidth: 120 },
        {
          prop: 'buildDate',
          label: '建池时间',
          sortable: false,
          minWidth: 120
        },
        {
          prop: 'sumFlow',
          label: '累计流量（m3）',
          sortable: false,
          minWidth: 120
        },
        { prop: 'address', label: '地址', sortable: false, minWidth: 200 },
        {
          prop: 'lastRecordDate',
          label: '最新一次采集时间',
          sortable: false,
          minWidth: 120
        },
        {
          prop: 'createTime',
          label: '创建时间',
          sortable: false,
          minWidth: 120
        },
        {
          prop: 'formatStatus',
          label: '状态',
          sortable: false,
          minWidth: 70,
          slot: true
        },
        {
          prop: 'optSolt',
          label: '操作',
          sortable: false,
          slot: true,
          minWidth: 280
        }
      ]
    },
    handleView(projectId) {
      this.objectId = projectId
      this.dialogViewVisible = true
    },
    handleImport() {
      this.importType = 1
      this.importVisible = true
    },
    handleUploadImg(params) {
      this.importType = 2
      this.meterId = params.meterId
      this.importVisible = true
    },
    // 设置数据访问权限
    // 设置可视区域高度
    initHeight: function() {
      let _this = this
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          _this.tableHeight = window.innerHeight - 220
        })()
      }
    }
  },
  mounted() {
    this.initHeight()
    this.initColumns()
    this.initLoad()
  },
  filters: {
    /**
     * 状态格式化
     * @param status 当前显示状态
     * @param type 1 获取内容，2 获得颜色
     * @returns {string|*} 格式化后结果
     */
    statusFilter(status, type) {
      const statusMap = {
        1: ['正常', 'success'],
        0: ['无信号', 'danger']
      }
      if (1 == type) {
        return statusMap[status][0]
      } else {
        return statusMap[status][1]
      }
    }
  }
}
</script>

<style>
/* 顶部下划线 */
.dialog-mask .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
</style>
