<template>
  <div class="page-container">
    <!--工具栏-->
    <div class="toolbar" style="float:left;padding-top:10px;padding-left:15px;">
      <el-form
        :inline="true"
        :model="filters"
        :size="size"
        style="text-align:left;"
      >
        <el-form-item>
          <el-input
            v-model="filters.docTitle"
            placeholder="标题"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-search"
            label="查询"
            type="primary"
            @click="findTreeData()"
          />
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-plus"
            label="新增"
            type="success"
            perms="biogas:help:edit"
            @click="handleAdd"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--表格树内容栏-->
    <el-table
      :data="tableTreeDdata"
      stripe
      size="mini"
      style="width: 100%;"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <table-tree
        prop="docTitle"
        header-align="center"
        treeKey="docId"
        width="250"
        label="标题名称"
      >
      </table-tree>
      <el-table-column
        prop="parentName"
        header-align="center"
        align="center"
        width="120"
        label="上级标题"
      >
      </el-table-column>
      <el-table-column
        prop="sort"
        header-align="center"
        align="center"
        label="排序"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="left"
        width="280"
        label="操作"
      >
        <template slot-scope="scope">
          <base-button
            icon="fa fa-search"
            label="查看"
            type="primary"
            perms="biogas:help:view"
            v-if="scope.row.children.length == 0"
            @click="handleView(scope.row)"
          />
          <base-button
            icon="fa fa-edit"
            label="编辑"
            type="primary"
            perms="biogas:help:edit"
            @click="handleEdit(scope.row)"
          />
          <base-button
            icon="fa fa-trash"
            label="删除"
            perms="biogas:help:delete"
            v-if="scope.row.children.length == 0"
            type="danger"
            @click="handleDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <!-- 核验项目审核界面 -->
    <el-dialog
      :title="!objectId ? '新增帮助信息' : '修改帮助信息'"
      width="70%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <help-form
        :objectId="objectId"
        @close="dialogVisible = false"
        @reload="findTreeData()"
      />
    </el-dialog>
  </div>
</template>

<script>
import TableTree from '@/components/TableTree' // 树型列表
import BaseButton from '@/components/BaseButton' // 公共按钮
import HelpForm from './HelpForm'
let _this = null // 定义一个全局能调用的this
export default {
  name: 'help',
  components: {
    TableTree,
    BaseButton,
    HelpForm
  },
  data() {
    return {
      size: 'small', // 样式图标大小
      loading: false,
      tableTreeDdata: [],
      dialogVisible: false,
      tableHeight: window.innerHeight - 220, // 表格的高度
      filters: {
        docTitle: ''
      },
      queryParams: {}, // 查询条件
      dialogViewVisible: false, //查看详细页面窗口打开标识
      objectId: null // 对象主键ID,用户于修改时进行传递
    }
  },
  methods: {
    findTreeData() {
      this.queryParams.docTitle = this.filters.docTitle
      this.loading = true
      this.$api.biogas.findDocHelpTree(this.queryParams).then(res => {
        this.tableTreeDdata = res.data
        this.loading = false
      })
    },
    // 设置可视区域高度
    initHeight: function() {
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          _this.tableHeight = window.innerHeight - 220
        })()
      }
    },
    //显示详细页面
    handleView(row) {
      let params = row.docId
      let route = _this.$router.resolve({
        path: '/biogas/HelpView',
        query: {
          params
        }
      })
      window.open(route.href, '_blank')
    },
    handleAdd() {
      this.dialogVisible = true
      this.objectId = null
    },
    // 显示编辑界面
    handleEdit(row) {
      this.dialogVisible = true
      this.objectId = row.docId
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确认删除选中记录吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$api.biogas.deleteDoc(row.docId).then(res => {
          if (res.code === 200) {
            this.findTreeData()
            this.$message.success('删除成功')
          }
        })
      })
    }
  },
  mounted() {
    _this = this
    this.findTreeData()
    this.initHeight()
  }
}
</script>

<style>
/* 顶部下划线 */
.dialog-mask .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
.dialog-mask .el-dialog__body {
  padding: 10px 20px;
}
</style>
