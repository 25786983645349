<template>
  <el-form
    :model="form"
    :rules="formRules"
    ref="dataForm"
    @keyup.enter.native="submitForm()"
    label-width="100px"
    size="small"
    style="text-align:left;"
  >
    <el-row>
      <el-col :span="12">
        <el-form-item label="角色编码">
          <el-input v-model="form.roleCode" :disabled="true"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="角色名称">
          <el-input v-model="form.roleName" :disabled="true"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="23">
        <el-form-item
          label="数据范围"
          prop="dataScope"
          style="text-align:left;"
        >
          <el-radio-group v-model="form.dataScope" @change="resetForm">
            <el-radio
              border
              style="margin-right: 4px;margin-top: 5px;margin-left: 0px;"
              :label="dict.dictValue"
              :key="dict.dictValue"
              v-for="dict in dataScopeOptions"
              >{{ dict.dictLabel }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :span="1" class="icon-list__tips">
        <el-tooltip placement="top" effect="light" style="padding: 10px;">
          <div slot="content">
            <p>指定数据权限范围类型，多个角色同时指定，之间为或者关系</p>
          </div>
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-col>
    </el-row>
    <!--未定义和全部数据时，不需要选择业务数据-->
    <el-row v-if="form.dataScope != '-1' && form.dataScope != '1'">
      <el-col :span="23">
        <el-form-item label="控制业务范围">
          <el-select
            v-model="selectBizScopes"
            style="width: 100%;"
            multiple
            v-if="form.dataScope != '6'"
          >
            <el-option
              v-for="item in bizScopesOptions"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="form.bizScope"
            style="width: 100%;"
            clearable
            @change="handleSelectBiz"
            v-if="form.dataScope == '6'"
          >
            <el-option
              v-for="item in bizScopesOptions"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="1" class="icon-list__tips">
        <el-tooltip placement="top" effect="light" style="padding: 10px;">
          <div slot="content">
            <p>
              在addFilter 权限过滤的时候指定适应的业务范围，不指定代表所有生效.
            </p>
            <p>如：有的功能看本部门，有的功能看本公司；</p>
            <p>新的业务范围从字典 sys_role_biz_scope 类型添加。</p>
            <p>
              选择指定业务数据类型时，只能选择一个控制业务范围，由业务范围定义访问数据内容
            </p>
          </div>
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-row v-if="form.dataScope == '5'">
      <el-col :span="23">
        <el-form-item label="指定部门数据">
          <el-cascader
            :props="orgProps"
            :options="orgOptions"
            v-model="selectedOrgOptions"
            clearable
            style="width: 100%;"
            ref="orgCascader"
            placeholder="请选择部门"
          ></el-cascader>
        </el-form-item>
      </el-col>
      <el-col :span="1" class="icon-list__tips">
        <el-tooltip placement="top" effect="light" style="padding: 10px;">
          <div slot="content">
            <p>
              1、未指定业务范围时，所有功能模块只能查看指定部门及部门下所有子部门的数据。
            </p>
            <p>
              2、指定业务范围时，只能查看特定业务指定部门及部门下所有子部门的数据。
            </p>
          </div>
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </el-col>
    </el-row>
    <el-row v-if="form.dataScope == '6'">
      <el-col :span="24">
        <el-form-item label="指定业务数据">
          <el-transfer
            style="text-align: left;"
            :titles="['待分配', '已分配']"
            filterable
            filter-placeholder="输入名称查询"
            :filter-method="filterMethod"
            :render-content="renderContent"
            v-model="selectBizData"
            :data="bizDataOptions"
          >
          </el-transfer>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item style="text-align: right">
      <el-button @click="closeWindow">取 消</el-button>
      <el-button type="primary" @click="submitForm" :loading="editLoading"
        >提 交</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'dataForm',
  props: {
    objectId: Number // 在子组件中通过props接收编辑的对象主键
  },
  data() {
    return {
      dataScopeOptions: [], // 角色数据范围选择项
      bizScopesOptions: [], // 角色业务范围选择项
      selectBizScopes: [], // 选中的业务范围信息
      orgOptions: [], // 机构待选项
      selectedOrgOptions: [], // 选中的部门信息
      orgProps: {
        label: 'orgName',
        value: 'orgId',
        multiple: true, // 开启多选模式
        checkStrictly: true // 开启任意一级选项
      },
      bizDataOptions: [], // 待授权的业务数据
      selectBizData: [], // 已经授权的业务数据
      form: {
        roleId: null,
        roleCode: '',
        roleName: '',
        dataScope: '-1',
        bizScope: '',
        bizData: ''
      },
      formRules: {
        dataScope: [
          { required: true, message: '请选择数据范围', trigger: 'blur' }
        ]
      },
      editLoading: false
    }
  },
  watch: {
    // 通过watch函数监控objectId的变化，把值copy到本地的对象
    objectId: {
      immediate: true, // 很重要！！！ 开启首次赋值监听
      deep: true,
      handler(val) {
        if (val == null || val === 0) {
          this.resetForm()
          return
        }
        this.findObj(val)
      }
    }
  },
  methods: {
    // 加载各类选择项
    loadOptions: function() {
      // 加载角色数据范围选择项
      this.$api.dict.findDictByType('sys_role_data_scope').then(res => {
        if (res.code === 200) {
          this.dataScopeOptions = res.data
        }
      })
      // 加载角色业务范围选择项
      this.$api.dict.findDictByType('sys_role_biz_scope').then(res => {
        if (res.code === 200) {
          this.bizScopesOptions = res.data
        }
      })
      // 加载机构选择项
      this.$api.org.findOrgTree().then(res => {
        if (res.code === 200) {
          this.orgOptions = res.data
        }
      })
    },
    // 表单提交
    submitForm: function() {
      // 当时数据范围类型为非指定业务类型时可以是多选择模块，使用逗号隔开
      if (this.selectBizScopes.length > 0) {
        this.form.bizScope = this.selectBizScopes.join(',')
      }
      if (this.selectedOrgOptions.length > 0) {
        let orgIds = ''
        for (let i = 0; i < this.selectedOrgOptions.length; i++) {
          // 部门数据格式为:1,2,35，ID是按级排序，但量保存时只保存最后一个级别的ID就可以了
          let oids = this.selectedOrgOptions[i]
          if (orgIds.length === 0) {
            orgIds = oids[oids.length - 1]
          } else {
            orgIds = orgIds + ',' + oids[oids.length - 1]
          }
        }
        this.form.bizData = orgIds
      }
      // 选中业务数据
      if (this.selectBizData.length > 0) {
        this.form.bizData = this.selectBizData.join(',')
      }
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.editLoading = true
          this.$api.role.saveRoleDataScope(this.form).then(res => {
            this.editLoading = false
            if (res.code == 200) {
              this.$message.success('操作成功！')
              this.$emit('reload') //列表信息
              this.closeWindow()
            } else {
              this.$message.error('操作失败,' + res.msg)
            }
          })
        }
      })
    },
    /**
     * 根据对象ID查询对象信息
     * @param id
     */
    findObj: function(id) {
      this.resetForm()
      this.$api.role.getObj(id).then(res => {
        if (res.code == 200) {
          this.form = Object.assign({}, res.data)
          // 当前数据范围非指定业务时
          if (
            this.form.dataScope != '6' &&
            this.form.bizScope != null &&
            this.form.bizScope != ''
          ) {
            this.selectBizScopes = this.form.bizScope.split(',')
          }
          // 为指定业务类型时
          if (
            this.form.dataScope == '6' &&
            this.form.bizData != null &&
            this.form.bizData != ''
          ) {
            this.handleSelectBiz(this.form.bizScope)
            this.selectBizData = this.form.bizData.split(',')
          }
          // 设置指定的部门信息
          if (this.form.dataScope == '5') {
            this.setOrgData(this.form.bizData)
          }
        } else {
          this.$message.error('查询失败,' + res.msg)
        }
      })
    },
    // 根据 orgID获得org对象
    setOrgData(bizData) {
      bizData.split(',').map(orgId => {
        this.$api.org.getObj(orgId).then(res => {
          if (res.code == 200) {
            let orgPath = res.data.path
            let oid = orgPath
              .substring(2, orgPath.length)
              .split(',')
              .map(value => {
                return parseInt(value)
              })
            this.selectedOrgOptions.push(oid)
          }
        })
      })
    },
    // 指定业务数据时查询过滤
    filterMethod: function(query, item) {
      return item.label.indexOf(query) > -1
    },
    renderContent: function(h, option) {
      return h('span', { domProps: { title: option.label } }, option.label)
    },
    // 选择业务类型时加载业务数据
    handleSelectBiz: function(val) {
      this.bizDataOptions = [] // 待授权的业务数据
      this.selectBizData = [] // 已经授权的业务数据
      let obj = {}
      obj = this.bizScopesOptions.find(item => {
        //model就是上面的数据源
        return item.dictValue === val //筛选出匹配数据
      })
      let bizUrl = obj.remark // 获得业务数据查询地址
      this.$api.role.findBizDataScope(bizUrl).then(res => {
        if (res.code == 200) {
          // 待分配的
          const unAssData = res.data
          for (let i = 0; i < unAssData.length; i++) {
            this.bizDataOptions.push({
              key: unAssData[i].key,
              label: unAssData[i].value
            })
          }
        } else {
          this.$message.error('获取授权数据失败:' + res.msg)
        }
      })
    },
    // 关闭新增修改窗口
    closeWindow: function() {
      this.resetForm()
      this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
    },
    // 重置表单信息
    resetForm: function() {
      this.form.bizScope = '' // 设置业务为空
      this.selectedOrgOptions = [] // 指定部门数据为空
      this.selectBizScopes = [] // 选择中业务范围为空
      this.bizDataOptions = [] // 待授权的业务数据
      this.selectBizData = [] // 已经授权的业务数据
    }
  },
  mounted() {
    this.loadOptions()
  }
}
</script>

<style>
/**设置穿梭框的宽度**/
.el-transfer-panel {
  width: 35%;
  /**height: 500px;**/
}
</style>
