<template>
  <el-form
    :model="form"
    :rules="dataRule"
    ref="dataForm"
    @keyup.enter.native="submitForm()"
    label-width="100px"
    size="small"
    style="text-align:left;"
  >
    <el-row>
      <el-col :span="12">
        <el-form-item label="字典类型" prop="dictType">
          <el-input
            v-model="form.dictType"
            placeholder="请输入字典类型"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="字典名称" prop="dictName">
          <el-input v-model="form.dictName" placeholder="字典名称"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="字典标签" prop="dictLabel">
          <el-input
            v-model="form.dictLabel"
            placeholder="请输入字典标签"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="字典键值" prop="dictValue">
          <el-input
            v-model="form.dictValue"
            placeholder="请输入字典键值"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="排序" prop="sort">
          <el-input v-model="form.sort" placeholder="请输入排序"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="备注说明" prop="remark">
          <el-input
            type="textarea"
            :rows="2"
            v-model="form.remark"
            placeholder="请输入备注说明"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item>
      <el-button @click="closeWindow">取消</el-button>
      <el-button type="primary" @click="submitForm" :loading="editLoading"
        >提交</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
let _this = null // 定义一个全局能调用的this
export default {
  name: 'dataForm',
  props: {
    objectId: Number // 在子组件中通过props接收编辑的对象主键
  },
  data() {
    return {
      operation: true, // true:新增, false:编辑
      form: {
        dictId: null,
        dictType: '',
        dictName: '',
        dictLabel: '',
        dictValue: '',
        sort: 1,
        remark: ''
      },
      dataRule: {
        dictType: [
          { required: true, message: '字典类型不能为空', trigger: 'blur' }
        ],
        dictName: [
          { required: true, message: '字典名称不能为空', trigger: 'blur' }
        ],
        dictLabel: [
          { required: true, message: '字典标签不能为空', trigger: 'blur' }
        ],
        dictValue: [
          { required: true, message: '字典键值不能为空', trigger: 'blur' }
        ]
      },
      editLoading: false
    }
  },
  watch: {
    // 通过watch函数监控objectId的变化，把值copy到本地的对象
    objectId: {
      immediate: true, // 很重要！！！ 开启首次赋值监听
      deep: true,
      handler(val) {
        if (val == null || val === 0) {
          this.resetForm()
          return
        }
        this.findObj(val)
      }
    }
  },
  methods: {
    // 表单提交
    submitForm: function() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          _this.editLoading = true
          this.$api.dict
            .save(_this.form, this.operation ? 'post' : 'put')
            .then(res => {
              _this.editLoading = false
              if (res.code == 200) {
                _this.$message.success('操作成功！')
                _this.$emit('reload') //列表信息
                _this.closeWindow()
              } else {
                _this.$message.error('操作失败,' + res.msg)
              }
            })
        }
      })
    },
    /**
     * 根据对象ID查询对象信息
     * @param id
     */
    findObj: function(id) {
      this.operation = false
      this.$api.dict.getObj(id).then(res => {
        if (res.code == 200) {
          _this.form = Object.assign({}, res.data)
        } else {
          this.$message.error('查询失败,' + res.msg)
        }
      })
    },
    // 关闭新增修改窗口
    closeWindow: function() {
      this.resetForm()
      this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
    },
    // 重置表单信息
    resetForm: function() {
      this.form = {
        dictId: null,
        dictType: '',
        dictName: '',
        dictLabel: '',
        dictValue: '',
        sort: 1,
        remark: ''
      }
      this.objectId = 0
    }
  },
  mounted() {
    _this = this
  }
}
</script>

<style scoped></style>
