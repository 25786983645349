<!--封装基础简单表格操作--->
<template>
  <div>
    <!--表格栏-->
    <el-table
      :data="data.list"
      :highlight-current-row="highlightCurrentRow"
      @selection-change="selectionChange"
      @current-change="handleCurrentChange"
      v-loading="loading"
      element-loading-text="拼命加载中"
      :border="border"
      :stripe="stripe"
      :show-overflow-tooltip="showOverflowTooltip"
      :max-height="maxHeight"
      :size="size"
      :align="align"
      :showSelection="showSelection"
      style="width:100%;"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
        v-if="showSelection"
      ></el-table-column>
      <template v-for="column in columns">
        <el-table-column
          v-if="!column.slot"
          header-align="center"
          align="center"
          :prop="column.prop"
          :label="column.label"
          :width="column.width"
          :min-width="column.minWidth"
          :fixed="column.fixed"
          :key="column.prop"
          :type="column.type"
          :formatter="column.formatter"
          :sortable="column.sortable == null ? true : column.sortable"
        >
        </el-table-column>
        <el-table-column
          v-else
          header-align="center"
          align="center"
          :prop="column.prop"
          :label="column.label"
          :width="column.width"
          :min-width="column.minWidth"
          :fixed="column.fixed"
          :key="column.prop"
          :type="column.type"
          :sortable="column.sortable == null ? true : column.sortable"
        >
          <!--使用自定义渲染-->
          <template slot-scope="scope">
            <slot
              :name="column.prop"
              :$index="scope.$index"
              :row="scope.row"
            ></slot>
            <!-- 对应slot name -->
          </template>
        </el-table-column>
      </template>

      <el-table-column
        label="操作"
        width="185"
        fixed="right"
        v-if="showOperation"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <base-button
            icon="fa fa-edit"
            label="编辑"
            :perms="permsEdit"
            :size="size"
            type="primary"
            @click="handleEdit(scope.$index, scope.row)"
          />
          <base-button
            icon="fa fa-trash"
            label="删除"
            :perms="permsDelete"
            :size="size"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <!--分页栏-->
    <div class="toolbar" style="padding:10px;">
      <el-pagination
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="refreshPageRequest"
        :current-page="pageRequest.pageNum"
        :page-size="pageRequest.pageSize"
        :total="data.totalSize"
        :page-sizes="[10, 20, 100, 200, 300, 500]"
        style="float:right;"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton' // 公共按钮
export default {
  name: 'BaseTable',
  components: {
    BaseButton
  },
  props: {
    columns: Array, // 表格列配置
    data: Object, // 表格分页数据
    permsEdit: String, // 编辑权限标识
    permsDelete: String, // 删除权限标识
    size: {
      // 尺寸样式
      type: String,
      default: 'mini'
    },
    align: {
      // 文本对齐方式
      type: String,
      default: 'left'
    },
    maxHeight: {
      // 表格最大高度
      type: Number,
      default: 470
    },
    showOperation: {
      // 是否显示操作组件
      type: Boolean,
      default: true
    },
    border: {
      // 是否显示边框
      type: Boolean,
      default: false
    },
    stripe: {
      // 是否显示斑马线
      type: Boolean,
      default: true
    },
    highlightCurrentRow: {
      // // 是否高亮当前行
      type: Boolean,
      default: true
    },
    showOverflowTooltip: {
      // 是否单行显示
      type: Boolean,
      default: true
    },
    showSelection: {
      // 是否显示操作组件
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // 分页信息
      pageRequest: {
        pageNum: 1,
        pageSize: 10
      },
      loading: false, // 加载标识
      selections: [] // 列表选中列
    }
  },
  methods: {
    // 分页查询
    findPage: function() {
      this.loading = true
      // eslint-disable-next-line
      let callback = res => {
        this.loading = false
      }
      this.$emit('findPage', {
        pageRequest: this.pageRequest,
        callback: callback
      })
    },
    // 选择切换
    selectionChange: function(selections) {
      this.selections = selections
      this.$emit('selectionChange', { selections: selections })
    },
    // 选择切换
    handleCurrentChange: function(val) {
      this.$emit('handleCurrentChange', { val: val })
    },
    // 换页刷新
    refreshPageRequest: function(pageNum) {
      this.pageRequest.pageNum = pageNum
      this.findPage()
    },
    handleSizeChange(val) {
      this.pageRequest.pageNum = 1
      this.pageRequest.pageSize = val
      this.findPage()
    },
    // 编辑
    handleEdit: function(index, row) {
      this.$emit('handleEdit', { index: index, row: row })
    },
    // 删除
    handleDelete: function(index, row) {
      this.delete(row)
    },
    // 删除操作
    delete: function(row) {
      this.$confirm('确认删除选中记录吗？', '提示', {
        type: 'warning'
      })
        .then(() => {
          this.loading = true
          let callback = res => {
            if (res.code == 200) {
              this.$message.success('删除成功')
              this.findPage()
            } else {
              this.$message.error('操作失败, ' + res.msg)
            }
            this.loading = false
          }
          this.$emit('handleDelete', { row: row, callback: callback })
        })
        .catch(() => {})
    }
  },
  mounted() {
    this.refreshPageRequest(1)
  }
}
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>
