<template>
  <div class="detail__wrap">
    <div class="detail__wrap--header">
      <div class="detail__wrap--title">
        沼气工程基本信息
      </div>
    </div>
    <el-collapse-transition>
      <div class="detail__content">
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                项目名称
              </div>
              <div class="content__item--message">
                {{ form.projectName }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                项目类型
              </div>
              <div class="content__item--message">
                {{ getSelectOrValue('projectTypeSelector', form.projectType) }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                地址
              </div>
              <div class="content__item--message">
                {{ form.address }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                业主姓名
              </div>
              <div class="content__item--message">
                {{ form.contact }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                业主电话
              </div>
              <div class="content__item--message">
                {{ form.phone }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                建池时间
              </div>
              <div class="content__item--message">
                {{ form.buildDate }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                数据来源
              </div>
              <div class="content__item--message">
                {{ form.dataSource == 1 ? '档案数据' : '核验新增' }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                经度
              </div>
              <div class="content__item--message">
                {{ form.longitude }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                纬度
              </div>
              <div class="content__item--message">
                {{ form.latitude }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                项目规模（m³）
              </div>
              <div class="content__item--message">
                {{ form.projectSize }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                项目创建时间
              </div>
              <div class="content__item--message">
                {{ form.createTime }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                核验年度
              </div>
              <div class="content__item--message">
                {{ form.batchId }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                核验状态
              </div>
              <div class="content__item--message">
                {{ getVerStatus(form.verStatus) }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-collapse-transition>

    <div class="detail__wrap--header" style="margin-top: 20px;">
      <div class="detail__wrap--title">
        沼气工程核验信息
      </div>
    </div>
    <el-collapse-transition>
      <div class="detail__content">
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                使用情况
              </div>
              <div class="content__item--message">
                {{
                  getSelectOrValue(
                    'useStatusSelector',
                    form.projectVer.useStatus
                  )
                }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="form.projectVer.useStatus == 2">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                停用原因
              </div>
              <div class="content__item--message">
                {{
                  getSelectOrValue(
                    'stopReasonSelector',
                    form.projectVer.statusReason
                  )
                }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                停用时间
              </div>
              <div class="content__item--message">
                {{ form.projectVer.statusDate }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24" v-if="form.projectVer.useStatus == 2">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                停用拟处置方式
              </div>
              <div class="content__item--message">
                {{
                  getSelectOrValue('stopWaySelector', form.projectVer.statusWay)
                }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="form.projectVer.useStatus == 3">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                报废原因
              </div>
              <div class="content__item--message">
                {{
                  getSelectOrValue(
                    'scrapReasonSelector',
                    form.projectVer.statusReason
                  )
                }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                报废时间
              </div>
              <div class="content__item--message">
                {{ form.projectVer.statusDate }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24" v-if="form.projectVer.useStatus == 3">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                报废依据
              </div>
              <div class="content__item--message">
                {{
                  getSelectOrValue(
                    'scrapWaySelector',
                    form.projectVer.statusWay
                  )
                }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24" v-if="form.projectVer.useStatus == 3">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                报废承拆单位
              </div>
              <div class="content__item--message">
                {{ form.projectVer.demolishUnit }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                安全隐患
              </div>
              <div class="content__item--message">
                {{ getSelectOrValue('unSafeSelector', form.projectVer.unSafe) }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                手机定位地址
              </div>
              <div class="content__item--message">
                {{ form.projectVer.lbsAddr }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                核验人
              </div>
              <div class="content__item--message">
                {{ form.projectVer.verName }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                核验提交时间
              </div>
              <div class="content__item--message">
                {{ form.projectVer.verTime }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="form.verStatus == 3">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                审核人
              </div>
              <div class="content__item--message">
                {{ form.projectVer.approveUserName }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                归档时间
              </div>
              <div class="content__item--message">
                {{ form.projectVer.finishTime }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                核验照片
              </div>
              <div class="content__item--message" style="text-align: left;">
                <el-upload
                  action="#"
                  class="disUoloadSty"
                  list-type="picture-card"
                  :limit="3"
                  :auto-upload="false"
                  :file-list="smallImgList"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.smallPath"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-image-viewer
                  v-if="showViewer"
                  z-index="9999"
                  :on-close="closeViewer"
                  :url-list="getViewer()"
                />
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="this.rectificationSmallImgList.length > 0">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                整改通知书照片
              </div>
              <div class="content__item--message" style="text-align: left;">
                <el-upload
                  action="#"
                  class="disUoloadSty"
                  list-type="picture-card"
                  :limit="1"
                  :auto-upload="false"
                  :file-list="rectificationSmallImgList"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.smallPath"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-image-viewer
                  v-if="showViewer"
                  z-index="9999"
                  :on-close="closeViewer"
                  :url-list="getViewer()"
                />
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="form.projectVer.isPending === 1">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
              style="color: red"
            >
              <div class="content__item--label">
                是否待查项目
              </div>
              <div class="content__item--message" style="color: red">
                {{ form.projectVer.isPending === 1 ? '待查项目' : '正常核验' }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
              style="color: red"
            >
              <div class="content__item--label">
                待查原因
              </div>
              <div class="content__item--message" style="color: red">
                {{
                  getSelectOrValue(
                    'pendingReason',
                    form.projectVer.pendingReason
                  )
                }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                核验说明
              </div>
              <div class="content__item--message">
                <el-input
                  type="textarea"
                  :rows="2"
                  :disabled="true"
                  v-model="form.projectVer.remark"
                ></el-input>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="form.verStatus == 3">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                审核意见
              </div>
              <div class="content__item--message">
                <el-input
                  type="textarea"
                  :rows="2"
                  :disabled="true"
                  v-model="form.projectVer.approveOpn"
                ></el-input>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import fileDownload from 'js-file-download'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: '沼气工程历史核验页面',
  components: {
    ElImageViewer
  },
  props: {
    objectId: Number // 在子组件中通过props接收编辑的对象主键
  },
  data() {
    return {
      vertical: false,
      editLoading: false,
      selectorList: [], // 公共选项
      smallImgList: [], //缩略图地址
      imgList: [], // 大图地址
      rectificationSmallImgList: [], // 整改通知缩略图地址
      rectificationImgList: [], // 整改通知大图地址
      typeId: 1, // 图片类型，1-核验图片，2-整改通知书图片
      showViewer: false, // 显示大图
      imgIndex: 0, // 点击查看哪张图片
      disabled: false,
      form: {}, // 页面显示表单信息
      approveForm: {
        projectId: null,
        verId: null,
        approveStatus: null,
        approveOpn: ''
      }, // 审核表单信息
      dataRule: {
        approveStatus: [
          { required: true, message: '请选择审核意见', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    // 通过watch函数监控objectId的变化，把值copy到本地的对象
    objectId: {
      immediate: true, // 很重要！！！ 开启首次赋值监听
      deep: true,
      handler(val) {
        if (val == null) {
          return
        }
        this.findObj(val)
      }
    }
  },
  methods: {
    //初始化加载
    initLoad() {
      // 加载公共选择项
      this.$api.biogas.getSelector(2).then(res => {
        if (res.code === 200) {
          this.selectorList = res.data
        }
      })
    },
    /**
     * 根据ID查询对象信息
     * @param id
     */
    findObj: function(id) {
      this.smallImgList = []
      this.imgList = []
      this.rectificationSmallImgList = [] // 整改通知缩略图地址
      this.rectificationImgList = [] // 整改通知大图地址
      this.$api.biogas.getPojectHisInfo(id).then(res => {
        if (res.code == 200) {
          this.form = Object.assign({}, res.data)
          // 拼接得到图片信息对象
          let imgs = this.form.imgs
          for (let i = 0; i < imgs.length; i++) {
            let obj = {
              id: i,
              typeId: 1,
              smallPath: imgs[i].smallPath
            }
            this.smallImgList.push(obj)
            // 将图片信息对象，push到imgList数组中
            this.imgList.push(imgs[i].imgPath)
          }
          let rectificationImgs = this.form.rectificationImgs
          for (let i = 0; i < rectificationImgs.length; i++) {
            let obj = {
              id: i,
              typeId: 2,
              smallPath: rectificationImgs[i].smallPath
            }
            this.rectificationSmallImgList.push(obj)
            // 将图片信息对象，push到imgList数组中
            this.rectificationImgList.push(rectificationImgs[i].imgPath)
          }
        } else {
          this.$message.error('查询失败,' + res.msg)
        }
      })
    },
    // 组装如项目类型、停用原因等选项值
    getSelectOrValue(keyType, key) {
      let keyArry = String(key).split(',')
      let str = ''
      let kvList = this.selectorList[keyType]
      kvList.forEach(item => {
        for (let i = 0; i < keyArry.length; i++) {
          if (item.key == String(keyArry[i])) {
            str = str + '  ' + item.value
          }
        }
      })
      return str.trim()
    },
    // 显示图片大图
    handlePictureCardPreview(file) {
      this.typeId = file.typeId
      this.imgIndex = file.id
      this.showViewer = true
    },
    // 关闭预览大图
    closeViewer() {
      this.showViewer = false
    },
    getViewer() {
      let tempImgList = [...this.imgList] //所有图片地址
      if (this.typeId === 2) {
        tempImgList = [...this.rectificationImgList]
      }
      if (this.imgIndex === 0) {
        return tempImgList
      }
      // 调整图片顺序，把当前图片放在第一位
      let start = tempImgList.splice(this.imgIndex)
      let remain = tempImgList.splice(0, this.imgIndex)
      return start.concat(remain) //将当前图片调整成点击缩略图的那张图片
    },
    // 下载图片
    handleDownload(file) {
      this.$api.biogas.downLoadImg(file.id).then(res => {
        fileDownload(res, file.name)
      })
    },
    // 关闭新增修改窗口
    closeWindow: function() {
      this.$emit('reload') //列表信息
      this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
    },
    getVerStatus(verState) {
      if (verState === -1) {
        return '审核退回'
      }
      if (verState === 1) {
        return '未核验'
      }
      if (verState === 2) {
        return '审核中'
      }
      if (verState === 3) {
        return '已归档'
      }
      if (verState === 4) {
        return '待上传报废材料'
      }
    }
  },
  mounted() {
    this.initLoad()
  }
}
</script>

<style lang="less">
.detail__wrap {
  background: #fff;
  border-radius: 2px;
  .detail__wrap--header {
    display: flex;
    height: 32px;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .detail__wrap--title {
      display: inline-block;
      font-size: 18px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      margin-left: 10px;
      &:after {
        content: '';
        width: 3px;
        height: 100%;
        background: #2d8cf0;
        border-radius: 2px;
        position: absolute;
        top: 1px;
        left: -10px;
      }
    }
  }
  .detail__content {
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    .detail__content--flex {
      display: flex;
      height: 100%;
    }
    .content__item--label {
      font-size: 14px;
      padding: 8px 16px;
      width: 150px;
      text-align: left;
      background-color: #fafafa;
      border-right: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
    .content__item--message {
      flex: 1;
      font-size: 14px;
      padding: 8px 16px;
      line-height: 20px;
      color: #606266;
      width: 100%;
      border-right: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
