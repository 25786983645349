<template>
  <div class="page-container">
    <!--工具栏-->
    <div class="toolbar" style="float:left;padding-top:10px;padding-left:15px;">
      <el-form
        :inline="true"
        :model="filters"
        :size="size"
        style="text-align:left;"
      >
        <el-form-item>
          <el-input
            v-model="filters.docTitle"
            placeholder="公告标题"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <base-button icon="fa fa-search" label="查询" type="primary" />
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-plus"
            label="新增"
            type="success"
            perms="biogas:notice:edit"
            @click="handleAdd"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--表格内容栏-->
    <base-table
      :data="pageResult"
      :columns="columns"
      :maxHeight="tableHeight"
      :showOperation="showOperation"
      @findPage="findPage"
    >
      <template slot="formatDocType" slot-scope="scope">
        <el-tag :type="scope.row.docType | docTypeFilter(2)">{{
          scope.row.docType | docTypeFilter(1)
        }}</el-tag>
      </template>
      <template slot="formatNoticeType" slot-scope="scope">
        <el-tag :type="scope.row.noticeType | noticeTypeFilter(2)">{{
          scope.row.noticeType | noticeTypeFilter(1)
        }}</el-tag>
      </template>
      <template slot="optSolt" slot-scope="scope">
        <base-button
          icon="fa fa-address-card"
          label="查看"
          size="mini"
          type="primary"
          @click="handleView(scope.row)"
        />
        <base-button
          icon="fa fa-edit"
          label="编辑"
          type="warning"
          perms="biogas:notice:edit"
          @click="handleEdit(scope.row)"
        />
        <base-button
          icon="fa fa-trash"
          label="删除"
          perms="biogas:notice:delete"
          type="danger"
          @click="handleDelete(scope.row)"
        />
      </template>
    </base-table>
    <!-- 核验项目审核界面 -->
    <el-dialog
      :title="!objectId ? '新增通知公告' : '修改通知公告'"
      width="70%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <notice-form
        :objectId="objectId"
        @close="dialogVisible = false"
        @reload="findPage(null)"
      />
    </el-dialog>
    <!-- 核验项目审核界面 -->
    <el-dialog
      title="通知公告详细"
      width="70%"
      :visible.sync="dialogViewVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <publicity-view :objectId="objectId" @close="dialogViewVisible = false" />
    </el-dialog>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import BaseButton from '@/components/BaseButton'
import NoticeForm from './NoticeForm'
let _this = null // 定义一个全局能调用的this
export default {
  name: 'notice',
  components: {
    BaseTable,
    BaseButton,
    NoticeForm
  },
  data() {
    return {
      size: 'small', // 样式图标大小
      showOperation: false, // 不显示模板的操作状态栏
      dialogVisible: false, // 打开新增和编辑窗口
      tableHeight: window.innerHeight - 220, // 表格的高度
      filters: {
        docTitle: ''
      },
      docTypeOptions: [
        { value: 2, label: '通知', color: 'success' },
        { value: 3, label: '公告', color: 'warning' }
      ], // 公告类型
      noticeTypeOptions: [
        { value: 1, label: '核验员', color: 'success' },
        { value: 2, label: '县级', color: 'warning' },
        { value: 3, label: '市级', color: 'info' }
      ], // 公告类型
      columns: [], // 初始表头信息
      pageRequest: { pageNum: 1, pageSize: 10 }, // 分页请示参数信息, 当前页,每页大小
      pageResult: {}, // 分页查询结果集
      dialogViewVisible: false, //查看详细页面窗口打开标识
      objectId: null // 对象主键ID,用户于修改时进行传递
    }
  },
  methods: {
    /**
     * 分页查询数据
     * @param pageParams 分页请求参数
     */
    findPage(pageParams) {
      if (pageParams !== null) {
        this.pageRequest = pageParams.pageRequest
      }
      this.pageRequest.Q_docTitle_S_LK = this.filters.docTitle
      this.$api.biogas
        .findNoticePage(this.pageRequest)
        .then(res => {
          if (res.code === 200) {
            this.pageResult = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .then(pageParams != null ? pageParams.callback : '')
    },
    /***
     * 初始化列表显示
     */
    initColumns: function() {
      this.columns = [
        {
          prop: 'docTitle',
          label: '公告标题',
          sortable: false,
          minWidth: 180
        },
        {
          prop: 'formatDocType',
          label: '公告类型',
          sortable: false,
          slot: true
        },
        {
          prop: 'formatNoticeType',
          label: '发送对象类型',
          sortable: false,
          slot: true
        },
        {
          prop: 'publishName',
          label: '发布者',
          sortable: false,
          minWidth: 60
        },
        {
          prop: 'createTime',
          label: '发布时间',
          sortable: false,
          minWidth: 120
        },
        {
          prop: 'optSolt',
          label: '操作',
          sortable: false,
          slot: true,
          minWidth: 120
        }
      ]
    },
    // 设置可视区域高度
    initHeight: function() {
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          _this.tableHeight = window.innerHeight - 220
        })()
      }
    },
    handleAdd() {
      this.dialogVisible = true
      this.objectId = null
    },
    //显示详细页面
    handleView(row) {
      let params = row.docId
      let route = _this.$router.resolve({
        path: '/biogas/NoticeView',
        query: {
          params
        }
      })
      window.open(route.href, '_blank')
    },
    // 显示编辑界面
    handleEdit(row) {
      this.dialogVisible = true
      this.objectId = row.docId
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确认删除选中记录吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$api.biogas.deleteDoc(row.docId).then(res => {
          if (res.code === 200) {
            this.findPage(null)
            this.$message.success('删除成功')
          }
        })
      })
    }
  },
  mounted() {
    _this = this
    this.initHeight()
    this.initColumns()
  },
  filters: {
    docTypeFilter(status, type) {
      for (let i = 0; i < _this.docTypeOptions.length; i++) {
        if (status === _this.docTypeOptions[i].value) {
          if (1 == type) {
            return _this.docTypeOptions[i].label
          } else {
            return _this.docTypeOptions[i].color
          }
        }
      }
    },
    noticeTypeFilter(status, type) {
      for (let i = 0; i < _this.noticeTypeOptions.length; i++) {
        if (status === _this.noticeTypeOptions[i].value) {
          if (1 == type) {
            return _this.noticeTypeOptions[i].label
          } else {
            return _this.noticeTypeOptions[i].color
          }
        }
      }
    }
  }
}
</script>

<style>
/* 顶部下划线 */
.dialog-mask .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
.dialog-mask .el-dialog__body {
  padding: 10px 20px;
}
</style>
