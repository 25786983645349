<template>
  <el-form
    :model="form"
    :rules="dataRule"
    ref="dataForm"
    @keyup.enter.native="submitForm()"
    label-width="80px"
    size="small"
    style="text-align:left;"
  >
    <el-form-item label="登录账号" prop="userName">
      <el-input
        v-model="form.userName"
        placeholder="由数字和字母组成"
      ></el-input>
    </el-form-item>
    <el-form-item label="用户名称" prop="nickName">
      <el-input v-model="form.nickName" placeholder="请输入中文名称"></el-input>
    </el-form-item>
    <el-form-item label="密    码" prop="userPwd" v-if="operation">
      <el-input
        v-model="form.userPwd"
        type="password"
        placeholder="密码不少于6位"
      ></el-input>
    </el-form-item>
    <el-form-item label="密码强度" v-if="operation">
      <password-strength
        v-model="form.userPwd"
        style="padding-top: 10px;"
      ></password-strength>
      <span style="color:#666;"
        >密码由6-16个字符组成，为了您的账号安全，请不要使用全数字或全字母作为密码。</span
      >
    </el-form-item>
    <el-form-item label="确认密码" prop="checkPwd" v-if="operation">
      <el-input
        v-model="form.checkPwd"
        type="password"
        placeholder="确认密码与密码要保持一致"
      ></el-input>
    </el-form-item>
    <el-form-item label="联系手机" prop="phone">
      <el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
    </el-form-item>
    <el-form-item label="所属机构" prop="orgId">
      <el-cascader
        :props="orgProps"
        :options="orgOptions"
        v-model="selectedOrgOptions"
        clearable
        style="width: 100%;"
        ref="orgCascader"
        @change="orgSelectHandle"
        placeholder="请选择所属机构"
      ></el-cascader>
    </el-form-item>
    <el-form-item label="角色" prop="userRoles">
      <el-select
        v-model="userRoles"
        style="width: 100%;"
        multiple
        placeholder="请选择角色"
      >
        <el-option
          v-for="item in roles"
          :key="item.roleId"
          :label="item.roleName"
          :value="item.roleId"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="状态" prop="status">
      <el-radio-group v-model="form.status">
        <el-radio border label="1">正常</el-radio>
        <el-radio border label="0">禁用</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button @click="closeWindow">取消</el-button>
      <el-button type="primary" @click="submitForm" :loading="editLoading"
        >提交</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
let _this = null // 定义一个全局能调用的this
import PasswordStrength from '@/components/PasswordStrength'
export default {
  name: 'dataForm',
  components: { PasswordStrength },
  props: {
    objectId: Number // 在子组件中通过props接收编辑的对象主键
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value.length < 6 || value.length > 16) {
        callback(new Error('请输入6位 ~ 16位密码'))
      } else {
        if (this.form.checkPwd !== '') {
          this.$refs.dataForm.validateField('checkPwd')
        }
        callback()
      }
    }
    let validateCheckPwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'))
      } else if (value !== this.form.userPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      operation: true, // true:新增, false:编辑
      orgOptions: [], // 机构待选项
      selectedOrgOptions: [], // 选中的部门信息
      orgProps: {
        label: 'orgName',
        value: 'orgId',
        checkStrictly: true,
        lazy: true,
        // eslint-disable-next-line no-unused-vars
        lazyLoad(node, resolve) {
          let level = node.level
          setTimeout(() => {
            _this.$api.org.getOrgByPid(node.value).then(res => {
              if (res.code === 200) {
                res.data.forEach(item => {
                  item.leaf = level >= 4 //判断是否为末尾节点,5级到村级
                })
                resolve(res.data)
              }
            })
          }, 300)
        }
      },
      form: {
        userId: null,
        userName: '',
        userPwd: '',
        checkPwd: '',
        nickName: '',
        orgId: null,
        phone: '',
        status: '1'
      },
      dataRule: {
        userName: [
          { required: true, message: '登录账号不能为空', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '用户名称不能为空', trigger: 'blur' }
        ],
        // orgId: [{ required: true, message: '部门不能为空', trigger: 'blur' }],
        userPwd: [{ validator: validatePass, trigger: 'blur' }],
        checkPwd: [{ validator: validateCheckPwd, trigger: 'blur' }]
      },
      editLoading: false,
      roles: [], // 所有角色信息信息
      userRoles: [] // 选中的角色信息
    }
  },
  watch: {
    // 通过watch函数监控objectId的变化，把值copy到本地的对象
    objectId: {
      immediate: true, // 很重要！！！ 开启首次赋值监听
      deep: true,
      handler(val) {
        if (val == null) {
          this.resetForm()
          return
        }
        this.findObj(val)
      }
    },
    'form.orgId': {
      handler() {
        // 选择部门时选中的部门ID,通过该参数的变化关闭部门选择框
        if (this.$refs.orgCascader) {
          this.$refs.orgCascader.dropDownVisible = false //监听值发生变化就关闭它
        }
      }
    }
  },
  methods: {
    // 获取所有角色信息
    loadRoles: function() {
      this.$api.role.findAll().then(res => {
        if (res.code == 200) {
          _this.roles = res.data
        } else {
          _this.$message.error('查询角色信息失败:' + res.msg)
        }
      })
    },
    // 加载机构下拉框
    loadOrg: function() {
      this.$api.org.getOrgByPid(0).then(res => {
        if (res.code === 200) {
          res.data.forEach(item => {
            item.children = []
          })
          this.orgOptions = res.data
        }
      })
    },
    // 表单提交
    submitForm: function() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          // 角色ID使用逗号隔开
          _this.editLoading = true
          _this.form.roleIds = _this.userRoles.join(',')
          this.$api.user
            .save(_this.form, this.operation ? 'post' : 'put')
            .then(res => {
              _this.editLoading = false
              if (res.code == 200) {
                _this.$message.success('操作成功！')
                _this.$emit('reload') //列表信息
                _this.closeWindow()
              } else {
                _this.$message.error('操作失败,' + res.msg)
              }
            })
        }
      })
    },
    /**
     * 根据菜单ID查询对象信息
     * @param id
     */
    findObj: function(id) {
      // 不显示密码框
      this.operation = false
      this.$api.user.getObj(id).then(res => {
        if (res.code == 200) {
          _this.form = Object.assign({}, res.data)
          if (res.data.roleIds.length > 0 && res.data.roleIds != null) {
            _this.userRoles = res.data.roleIds.split(',').map(value => {
              return parseInt(value)
            })
          } else {
            _this.userRoles = []
          }
          _this.selectedOrgOptions = res.data.orgIds.split(',').map(value => {
            return parseInt(value)
          })
          _this.form.status = _this.form.status + ''
        } else {
          this.$message.error('查询失败,' + res.msg)
        }
      })
    },
    // 选中部门
    orgSelectHandle: function(val) {
      this.form.orgId = val[val.length - 1]
    },
    // 关闭新增修改窗口
    closeWindow: function() {
      this.resetForm()
      this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
    },
    // 重置表单信息
    resetForm: function() {
      this.form = {
        userId: null,
        userName: '',
        userPwd: '',
        checkPwd: '',
        nickName: '',
        orgId: null,
        phone: '',
        status: '1'
      }
      this.userRoles = []
      this.selectedOrgOptions = []
    }
  },
  mounted() {
    _this = this
    this.loadRoles()
    this.loadOrg()
  }
}
</script>

<style scoped></style>
