import { render, staticRenderFns } from "./Publicity.vue?vue&type=template&id=4da5a417&scoped=true&"
import script from "./Publicity.vue?vue&type=script&lang=js&"
export * from "./Publicity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4da5a417",
  null
  
)

export default component.exports