<!--封装基础按钮组件，方便通过权限标识进行显示和隐藏操作,若不需要时可以直接使用EL的按钮组件-->
<template>
  <el-button
    :size="size"
    :type="type"
    :icon="icon"
    :loading="loading"
    v-if="hasPerms(perms)"
    :disabled="disabled"
    @click="handleClick"
  >
    {{ label }}
  </el-button>
</template>

<script>
import { hasPermission } from '@/permission/index.js'
export default {
  name: 'BaseButton',
  props: {
    label: {
      // 按钮显示文本
      type: String,
      default: 'Button'
    },
    icon: {
      // 按钮显示图标
      type: String,
      default: ''
    },
    size: {
      // 按钮尺寸
      type: String,
      default: 'mini'
    },
    type: {
      // 按钮类型
      type: String,
      default: null
    },
    loading: {
      // 按钮加载标识
      type: Boolean,
      default: false
    },
    disabled: {
      // 按钮是否禁用
      type: Boolean,
      default: false
    },
    perms: {
      // 按钮权限标识，外部使用者传入
      type: String,
      default: null
    }
  },
  data() {
    return {}
  },
  methods: {
    handleClick: function() {
      // 按钮操作处理函数
      this.$emit('click', {})
    },
    hasPerms: function(perms) {
      // 根据权限标识和外部指示状态进行权限判断
      //默认显示按钮
      if (perms == null || perms == '') {
        return true
      } else {
        return hasPermission(perms)
      }
    }
  },
  mounted() {}
}
</script>

<style scoped></style>
