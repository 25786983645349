<template>
  <div class="page-container">
    <!--工具栏-->
    <div class="toolbar" style="float:left;padding-top:10px;padding-left:15px;">
      <el-form :inline="true" :model="filters" :size="size">
        <el-form-item>
          <el-input v-model="filters.meterNo" placeholder="仪表编号"></el-input>
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-search"
            label="查询"
            type="primary"
            @click="findPage(null)"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--表格内容栏-->
    <base-table
      :data="pageResult"
      :columns="columns"
      :maxHeight="tableHeight"
      :showOperation="showOperation"
      @findPage="findPage"
    >
      <!--状态格式化 slot="formatStatus" 要与下列的表格prop 设置的值一致-->
      <template slot="formatStatus" slot-scope="scope">
        <el-tag :type="scope.row.status | statusFilter(2)">{{
          scope.row.status | statusFilter(1)
        }}</el-tag>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable' // 树型列表
import BaseButton from '@/components/BaseButton' // 公共按钮
export default {
  name: 'HouseholdMeter',
  components: {
    BaseTable,
    BaseButton
  },
  data() {
    return {
      size: 'small', // 样式图标大小
      showOperation: false, // 不显示模板的操作状态栏
      tableHeight: window.innerHeight - 220, // 表格的高度
      filters: {
        meterNo: ''
      },
      columns: [], // 初始表头信息
      pageRequest: { pageNum: 1, pageSize: 100 }, // 分页请示参数信息, 当前页,每页大小
      pageResult: {} // 分页查询结果集
    }
  },
  methods: {
    initLoad() {},
    /**
     * 分页查询数据
     * @param pageParams 分页请求参数
     */
    findPage: function(pageParams) {
      this.objectId = null
      if (pageParams !== null) {
        this.pageRequest = pageParams.pageRequest
      }
      this.pageRequest.Q_meterNo_S_LK = this.filters.meterNo
      this.$api.gas
        .findRecordPage(this.pageRequest)
        .then(res => {
          if (res.code === 200) {
            this.pageResult = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .then(pageParams != null ? pageParams.callback : '')
    },
    /***
     * 初始化列表显示
     */
    initColumns: function() {
      this.columns = [
        { prop: 'meterNo', label: '仪表编号', sortable: false, minWidth: 100 },
        { prop: 'contact', label: '户主姓名', sortable: false, minWidth: 120 },
        {
          prop: 'sumFlow',
          label: '累计流量（m3）',
          sortable: false,
          minWidth: 100
        },
        {
          prop: 'recordDate',
          label: '数据采集时间',
          sortable: false,
          minWidth: 180
        },
        {
          prop: 'createTime',
          label: '上报时间',
          sortable: false,
          minWidth: 180
        }
      ]
    },
    // 设置数据访问权限
    // 设置可视区域高度
    initHeight: function() {
      let _this = this
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          _this.tableHeight = window.innerHeight - 220
        })()
      }
    }
  },
  mounted() {
    this.initHeight()
    this.initColumns()
    this.initLoad()
  },
  filters: {
    /**
     * 状态格式化
     * @param status 当前显示状态
     * @param type 1 获取内容，2 获得颜色
     * @returns {string|*} 格式化后结果
     */
    statusFilter(status, type) {
      const statusMap = {
        1: ['正常', 'success'],
        0: ['无信号', 'danger']
      }
      if (1 == type) {
        return statusMap[status][0]
      } else {
        return statusMap[status][1]
      }
    }
  }
}
</script>

<style>
/* 顶部下划线 */
.dialog-mask .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
</style>
