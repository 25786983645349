<template>
  <div class="page-container">
    <!--查询工具栏-->
    <div class="toolbar" style="float:left;padding-top:10px;padding-left:15px;">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-input
            v-model="filters.dictName"
            placeholder="字典名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="filters.dictLabel"
            placeholder="字典标签"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-search"
            label="查询"
            type="primary"
            @click="findPage(null)"
          />
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-plus"
            label="新增"
            type="success"
            @click="handleAdd"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--表格内容栏-->
    <base-table
      :data="pageResult"
      :columns="columns"
      :maxHeight="tableHeight"
      @findPage="findPage"
      @handleEdit="handleEdit"
      @handleDelete="handleDelete"
    >
      <!--状态格式化 slot="formatStatus" 要与下列的表格prop 设置的值一致-->
      <template slot="formatStatus" slot-scope="scope">
        <el-tag :type="scope.row.status | statusFilter(2)">{{
          scope.row.status | statusFilter(1)
        }}</el-tag>
      </template>
    </base-table>

    <!-- 新增修改界面 -->
    <el-dialog
      :title="!objectId ? '新增' : '修改'"
      width="60%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      custom-class="role-mask"
    >
      <!-- 表单 -->
      <dict-form
        :objectId="objectId"
        @close="dialogVisible = false"
        @reload="findPage(null)"
      />
    </el-dialog>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable' // 基础列表
import BaseButton from '@/components/BaseButton' // 公共按钮
import DictForm from './DictForm'
let _this = null // 定义一个全局能调用的this
export default {
  name: 'Dict',
  components: {
    BaseTable,
    BaseButton,
    DictForm
  },
  data() {
    return {
      size: 'small', // 样式图标大小
      tableHeight: window.innerHeight - 220, // 表格的高度
      filters: {
        dictName: '',
        dictLabel: ''
      },
      columns: [], // 初始表头信息
      pageRequest: { pageNum: 1, pageSize: 10 }, // 分页请示参数信息, 当前页,每页大小
      pageResult: {}, // 分页查询结果集
      dialogVisible: false, // 新增和修改页面窗口打开标识
      objectId: null // 对象主键ID,用户于修改时进行传递
    }
  },
  methods: {
    /**
     * 分页查询数据
     * @param pageParams 分页请求参数
     */
    findPage: function(pageParams) {
      if (pageParams !== null) {
        this.pageRequest = pageParams.pageRequest
      }
      this.pageRequest.Q_dictName_S_LK = this.filters.dictName
      this.pageRequest.Q_dictLabel_S_LK = this.filters.dictLabel
      this.$api.dict
        .findPage(this.pageRequest)
        .then(res => {
          if (res.code === 200) {
            this.pageResult = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .then(pageParams != null ? pageParams.callback : '')
    },
    /***
     * 初始化列表显示
     */
    initColumns: function() {
      this.columns = [
        {
          prop: 'dictLabel',
          label: '字典标签',
          sortable: false,
          minWidth: 100
        },
        {
          prop: 'dictValue',
          label: '字典键值',
          sortable: false,
          minWidth: 100
        },
        { prop: 'dictName', label: '字典名称', sortable: false, minWidth: 100 },
        { prop: 'dictType', label: '字典类型', sortable: false, minWidth: 100 },
        { prop: 'sort', label: '排序', sortable: false, minWidth: 70 }
      ]
    },
    handleAdd: function() {
      this.dialogVisible = true
      this.objectId = null
    },
    // 批量删除
    handleDelete: function(parm) {
      this.$confirm('确认删除选中记录吗？？', '提示', {}).then(() => {
        this.$api.cattleFarm.deleteObj(parm.row.dictId).then(res => {
          if (res.code == 200) {
            this.$message.success('删除成功！')
            this.findPage(null)
          } else {
            this.$message.error('删除失败,' + res.msg)
          }
        })
      })
    },
    // 显示编辑界面
    handleEdit: function(parm) {
      this.dialogVisible = true
      this.objectId = parm.row.dictId
    },
    /**
     * 设置可视区域高度
     */
    initHeight: function() {
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          _this.tableHeight = window.innerHeight - 220
        })()
      }
    }
  },
  mounted() {
    _this = this
    this.initHeight()
    this.initColumns()
  }
}
</script>

<style>
/* 顶部下划线 */
.role-mask .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
</style>
