<template>
  <el-table
    v-loading="loading"
    :data="tableData"
    :header-cell-style="{ background: '#e9eff8' }"
    lazy
    border
    size="mini"
    :row-style="{ cursor: 'pointer' }"
    row-key="orgId"
    :load="load"
  >
    <el-table-column
      v-for="(item, index) in columns"
      :key="index"
      :label="item.label"
      :prop="item.prop"
    ></el-table-column>
    <el-table-column
      prop="orgType"
      header-align="center"
      align="center"
      label="类型"
    >
      <template slot-scope="scope">
        <el-tag v-if="scope.row.orgType === 1" size="small">省级</el-tag>
        <el-tag v-else-if="scope.row.orgType === 2" size="small" type="success"
          >市级</el-tag
        >
        <el-tag v-else-if="scope.row.orgType === 3" size="small" type="warning"
          >区/县级</el-tag
        >
        <el-tag v-else-if="scope.row.orgType === 4" size="small" type="info"
          >乡/镇级</el-tag
        >
        <el-tag v-else-if="scope.row.orgType === 5" size="small" type="info"
          >村/路级</el-tag
        >
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      columns: [], // 标题和 prop 中需要渲染的数据
      tableData: [], // 表格中的数据，如果有children有数据的话就会 出现 element 表格中会出现一个下拉图标
      uploadData: {
        tree: null,
        treeNode: null,
        resolve: null
      }
    }
  },
  methods: {
    initColumns() {
      this.columns = [
        { prop: 'orgName', label: '机构名称', sortable: false, minWidth: 100 },
        { prop: 'orgCde', label: '机构代码', sortable: false, minWidth: 60 },
        { prop: 'pathName', label: '机构路径', sortable: false, minWidth: 120 }
      ]
    },
    load(tree, treeNode, resolve) {
      // tree为点击那一行的数据
      this.uploadData.tree = tree
      this.uploadData.treeNode = treeNode
      this.uploadData.resolve = resolve
      let value = tree.orgId
      this.$api.org.getOrgByPid(value).then(res => {
        if (res.code == 200) {
          res.data.forEach(item => {
            //判断是否有子节点
            if (item.isParent == 1) {
              item.hasChildren = []
            }
          })
          //传入res.dicList加载下一级
          resolve(res.data)
        } else {
          this.$message.error('查询失败:' + res.msg)
          this.loading = false
        }
      })
    },
    loadData() {
      this.loading = true
      this.$api.org.getOrgByPid(1).then(res => {
        if (res.code == 200) {
          res.data.forEach(item => {
            item.hasChildren = []
          })
          this.tableData = res.data
          this.loading = false
        } else {
          this.$message.error('查询失败:' + res.msg)
          this.loading = false
        }
      })
    }
  },
  mounted() {
    this.initColumns()
    this.loadData()
  }
}
</script>

<style scoped></style>
