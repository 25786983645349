<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{ dataForm.docTitle }}</span>
      </div>
      <!-- 富文本内容展示部分 -->
      <div v-html="dataForm.docContent" @click="showImg($event)"></div>
      <el-image-viewer
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="[imgUrl]"
      />
    </el-card>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'helpView',
  components: {
    ElImageViewer
  },
  data() {
    return {
      docId: 1,
      dataForm: {
        docTitle: '', // 标题
        docContent: '' // 内容
      },
      imgUrl: '',
      showViewer: false
    }
  },
  methods: {
    findObj() {
      this.$api.biogas.findDocById(this.docId).then(res => {
        if (res.code == 200) {
          this.dataForm = Object.assign({}, res.data)
        } else {
          this.$message.error('查询失败,' + res.msg)
        }
      })
    },
    showImg(e) {
      if (e.target.tagName == 'IMG') {
        this.imgUrl = ''
        this.imgUrl = e.target.src
        this.showViewer = true
      }
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    }
  },
  mounted() {
    this.docId = this.$route.query.params
    this.findObj()
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped></style>
