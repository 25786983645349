<template>
  <el-form
    :model="form"
    :rules="dataRule"
    ref="dataForm"
    label-width="130px"
    size="small"
    style="text-align:left;"
  >
    <el-row>
      <el-col :span="24">
        <el-form-item label="巡查年度" prop="batchId">
          <el-date-picker
            v-model="form.batchId"
            type="year"
            placeholder="选择年"
            disabled
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="巡查地区" prop="orgId">
          <el-select
            v-model="cityValue"
            placeholder="请选择市"
            @change="chooseCity"
            style="width: 150px;"
            :disabled="cityDisabled"
          >
            <el-option
              v-for="item in cityList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="countyValue"
            placeholder="请选择区/县"
            @change="chooseCounty"
            :disabled="countyDisabled"
          >
            <el-option
              v-for="item in countyList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="streetValue"
            placeholder="请选择乡/镇"
            @change="chooseStreet"
            :disabled="streetDisabled"
          >
            <el-option
              v-for="item in streetList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="villageValue"
            placeholder="请选择村"
            @change="chooseVillage"
            style="width: 190px;"
          >
            <el-option
              v-for="item in villageList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="上轮核验情况" prop="hisUseStatus">
          <el-select
            v-model="form.hisUseStatus"
            style="width: 100%;"
            placeholder="请选择上轮核验情况"
          >
            <el-option
              v-for="item in hisUseOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="form.hisUseStatus === 1 || form.hisUseStatus === ''">
      <el-col :span="24">
        <el-form-item label="年度巡查目标(%)" prop="taskGoal">
          <el-slider v-model="form.taskGoal" show-input> </el-slider>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="巡查说明">
          <div
            class="content__item--message"
            style="text-align: left;font-size: 12px;"
          >
            1.巡查启动前提条件：巡查的地区下所有的户用沼气项目都要全部提交核验并且都审核归档，才能发起2022年度的项目巡查。<br />
            2.2021年度核验情况为：正常（间歇）使用、待查项目的户用沼气项目，巡查启动成功后请通知核验员在APP端进行巡查核验操作。<br />
            3.2021年度核验情况为“正常（间歇）使用的项目，2022年度的巡查必须大于30%，请按实际目标填报。<br />
            4.2021年度核验情况为：报废的项目巡查启动后，只需要县级能源在电脑服务端进行报废分类处理，“事实报废”情况需要上传报废材料。
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item>
      <el-button @click="closeWindow">取消</el-button>
      <el-button type="primary" @click="submitForm" :loading="editLoading"
        >巡查启动提交</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'dataForm',
  props: {
    objectId: Number // 在子组件中通过props接收编辑的对象主键
  },
  data() {
    return {
      operation: true, // true:新增, false:编辑
      cityList: [], // 市列表
      countyList: [], // 区/县列表
      streetList: [], // 乡/镇列表
      villageList: [], // 村列表
      cityValue: '', // 选中的市
      countyValue: '', // 选中的区县
      streetValue: '', // 选中的乡/镇
      villageValue: '', // 选中的村
      cityDisabled: false, //市下拉框禁止选择
      countyDisabled: false, //市下拉框禁止选择
      streetDisabled: false, //市下拉框禁止选择
      hisUseOptions: [
        { value: -1, label: '待查项目' },
        { value: 1, label: '正常(间歇)使用' },
        { value: 3, label: '报废' }
      ], // 使用情况
      typeList: [], // 项目建设来源
      form: {
        batchId: '2022',
        orgId: '',
        hisUseStatus: '',
        taskGoal: 31
      },
      dataRule: {
        orgId: [
          { required: true, message: '巡查地区不能为空', trigger: 'blur' }
        ],
        hisUseStatus: [
          { required: true, message: '请选择上年度核验类型', trigger: 'blur' }
        ]
      },
      editLoading: false
    }
  },
  watch: {},
  methods: {
    initLoad() {
      // 加载机构下拉框
      this.$api.org.getOrgByPid(1).then(resp => {
        if (resp.code === 200) {
          this.cityList = resp.data
        } else {
          this.$message.error(resp.msg)
        }
      })
      // 加载项目建设来源
      this.$api.dict.findDictByType('household_project_type').then(res => {
        if (res.code === 200) {
          this.typeList = res.data
        }
      })
    },
    // 表单提交
    submitForm: function() {
      if (this.form.hisUseStatus === 1) {
        if (this.form.taskGoal < 30) {
          this.$notify.error({
            title: '提示',
            message: '亲，年度巡查目标必须大于30%',
            duration: 0
          })
          return
        }
      } else {
        this.form.taskGoal = 100
      }
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.editLoading = true
          this.form.biogasType = 1 // 户用沼气池
          this.form.targetBatchId = 2021 // 针对2021年度核验项目进行复核验巡查
          this.$api.biogas.saveProjectRecheck(this.form).then(res => {
            this.editLoading = false
            if (res.code == 200) {
              this.$notify({
                title: '提示',
                message: res.msg,
                type: 'success',
                duration: 0
              })
              this.$emit('reload') //列表信息
              this.closeWindow()
            } else {
              this.$notify.error({
                title: '沼气设施巡查启动失败',
                message: res.msg,
                duration: 0
              })
            }
          })
        }
      })
    },
    // 关闭新增修改窗口
    closeWindow: function() {
      // this.resetForm()
      this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
    },
    // 重置表单信息
    resetForm: function() {
      this.form = {
        batchId: '2022',
        orgId: '',
        hisUseStatus: '',
        taskGoal: 31
      }
      this.cityValue = ''
      this.countyList = []
      this.countyValue = ''
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
    },
    /**选中市**/
    chooseCity: function(value) {
      this.countyList = []
      this.countyValue = ''
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
      this.form.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.countyList = resp.data
        }
      })
    },
    /**选中区县**/
    chooseCounty: function(value) {
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
      this.form.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.streetList = resp.data
        }
      })
    },
    /**选中乡/镇**/
    chooseStreet: function(value) {
      this.villageList = []
      this.villageValue = ''
      this.form.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.villageList = resp.data
        }
      })
    },
    /**选中村**/
    chooseVillage: function(value) {
      this.form.orgId = value
    },
    // 初始化区域选中信息
    initSelectOrg() {
      // 获取当前登录用户的机构信息
      // 因为区域选择框是市级开始，而且路径等是从 省级->市级->区级->乡级->村级
      let orgPath = Cookies.get('user_orgIds')
      // 分割路径ID
      let orgIds = orgPath.split(',')
      if (orgIds.length === 2) {
        // 当前账号到市级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.cityDisabled = true
      }
      // 当前账号到县级
      if (orgIds.length === 3) {
        // 只到区级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[2])
        this.chooseCounty(this.countyValue)
        this.cityDisabled = true
        this.countyDisabled = true
      }
      // 当前账号的机构到乡镇级
      if (orgIds.length === 4) {
        // 只到乡级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[2])
        this.chooseCounty(this.countyValue)
        this.streetValue = parseInt(orgIds[3])
        this.chooseStreet(this.streetValue)
        this.cityDisabled = true
        this.countyDisabled = true
        this.streetDisabled = true
      }
      if (orgIds.length === 5) {
        // 村级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[2])
        this.chooseCounty(this.countyValue)
        this.streetValue = parseInt(orgIds[3])
        this.chooseStreet(this.streetValue)
        this.villageValue = parseInt(orgIds[4])
        this.cityDisabled = true
        this.countyDisabled = true
        this.streetDisabled = true
      }
    }
  },
  mounted() {
    this.initLoad()
    this.initSelectOrg()
  }
}
</script>

<style>
.el-notification {
  position: fixed;
  top: 40% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.el-notification.right {
  top: 40% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.el-notification.left {
  top: 40% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
</style>
