<template>
  <div class="page-container">
    <!--工具栏-->
    <div class="toolbar" style="float:left;padding-top:10px;padding-left:15px;">
      <el-form :inline="true" :model="filters" :size="size">
        <el-form-item>
          <el-input
            v-model="filters.userName"
            placeholder="用户账号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="filters.nickName"
            placeholder="用户名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-cascader
            :props="orgProps"
            :options="orgOptions"
            v-model="selectedOrgOptions"
            @change="orgSelectHandle"
            clearable
            style="width: 300px;"
            ref="orgCascader"
            placeholder="请选择机构"
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-search"
            label="查询"
            type="primary"
            @click="findPage(null)"
          />
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-plus"
            label="新增"
            perms="sys:user:add"
            type="success"
            @click="handleAdd"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--表格内容栏-->
    <base-table
      :data="pageResult"
      :columns="columns"
      :maxHeight="tableHeight"
      :showOperation="showOperation"
      @findPage="findPage"
    >
      <!--状态格式化 slot="formatStatus" 要与下列的表格prop 设置的值一致-->
      <template slot="formatStatus" slot-scope="scope">
        <el-tag :type="scope.row.status | statusFilter(2)">{{
          scope.row.status | statusFilter(1)
        }}</el-tag>
      </template>
      <template slot="optSolt" slot-scope="scope">
        <base-button
          icon="fa fa-edit"
          label="编辑"
          size="mini"
          type="primary"
          @click="handleEdit(scope.row)"
        />
        <base-button
          icon="fa fa-trash"
          label="删除"
          size="mini"
          type="danger"
          @click="handleDelete(scope.row)"
        />
        <base-button
          icon="fa fa-database"
          label="数据权限"
          size="mini"
          type="warning"
          @click="handleDataPermission(scope.row)"
        />
        <base-button
          icon="fa fa-hand-pointer-o"
          label="重设密码"
          size="mini"
          type="info"
          @click="handlePwd(scope.row)"
        />
      </template>
    </base-table>
    <!-- 新增修改界面 -->
    <el-dialog
      :title="!objectId ? '新增' : '修改'"
      width="45%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <!-- 表单 -->
      <user-form
        :objectId="objectId"
        @close="dialogVisible = false"
        @reload="findPage(null)"
      />
    </el-dialog>

    <el-dialog
      title="用户分配数据权限"
      :visible.sync="dialogDataPermsVisible"
      width="60%"
    >
      <el-form
        :model="userInfo"
        :rules="permsRule"
        ref="permsForm"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="登录账号">
              <el-input v-model="userInfo.userName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户名称">
              <el-input v-model="userInfo.nickName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23">
            <el-form-item label="控制业务范围" prop="bizScope">
              <el-select
                v-model="userInfo.bizScope"
                style="width: 100%;"
                clearable
                @change="handleSelectBiz"
              >
                <el-option
                  v-for="item in bizScopesOptions"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="1" class="icon-list__tips">
            <el-tooltip placement="top" effect="light" style="padding: 10px;">
              <div slot="content">
                <p>新的业务范围从字典 sys_role_biz_scope 类型添加。</p>
                <p>每次只能选择一个控制业务范围，由业务范围定义访问数据内容</p>
                <p>需要设置多个业务范围时，请进行多次添加操作</p>
                <p>当前业务数据为空时，则清除已授权的业务信息</p>
              </div>
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="访问数据范围"
              prop="dataScope"
              style="text-align: left;"
            >
              <el-radio-group v-model="userInfo.dataScope">
                <el-radio border label="5">指定部门及以下数据</el-radio>
                <el-radio border label="6">指定业务数据</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="userInfo.dataScope == '5'">
          <el-col :span="24">
            <el-form-item label="指定部门数据">
              <el-cascader
                :props="orgScopeProps"
                :options="orgScopeOptions"
                v-model="selectedScopeOrgOptions"
                @change="orgSelectScopeHandle"
                clearable
                style="width: 100%;"
                placeholder="请选择机构"
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="userInfo.dataScope == '6'">
          <el-col :span="24">
            <el-form-item label="指定业务数据">
              <el-transfer
                style="text-align: left;"
                :titles="['待分配', '已分配']"
                filterable
                filter-placeholder="输入名称查询"
                :filter-method="filterMethod"
                :render-content="renderContent"
                v-model="selectBizData"
                :data="bizDataOptions"
              >
              </el-transfer>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="submitBizScope">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable' // 树型列表
import BaseButton from '@/components/BaseButton' // 公共按钮
import UserForm from './UserForm' // 用户信息新增和编辑页面
let _this = null // 定义一个全局能调用的this
export default {
  name: 'user',
  components: {
    BaseTable,
    BaseButton,
    UserForm
  },
  data() {
    return {
      size: 'small', // 样式图标大小
      showOperation: false, // 不显示模板的操作状态栏
      dialogDataPermsVisible: false, // 分配数据权限窗口显示
      tableHeight: window.innerHeight - 220, // 表格的高度
      orgScopeOptions: [], // 机构待选项
      selectedScopeOrgOptions: [], // 选中的部门信息
      orgScopeProps: {
        label: 'orgName',
        value: 'orgId',
        children: 'children',
        multiple: true, // 开启多选模式
        checkStrictly: true,
        lazy: true,
        // eslint-disable-next-line no-unused-vars
        lazyLoad(node, resolve) {
          let level = node.level
          setTimeout(() => {
            _this.$api.org.getOrgByPid(node.value).then(res => {
              if (res.code === 200) {
                res.data.forEach(item => {
                  item.leaf = level >= 4 //判断是否为末尾节点,5级到村级
                })
                resolve(res.data)
              }
            })
          }, 300)
        }
      },
      orgOptions: [], // 机构待选项
      selectedOrgOptions: [], // 选中的部门信息
      orgProps: {
        label: 'orgName',
        value: 'orgId',
        children: 'children',
        checkStrictly: true,
        lazy: true,
        // eslint-disable-next-line no-unused-vars
        lazyLoad(node, resolve) {
          let level = node.level
          setTimeout(() => {
            _this.$api.org.getOrgByPid(node.value).then(res => {
              if (res.code === 200) {
                res.data.forEach(item => {
                  item.leaf = level >= 4 //判断是否为末尾节点,5级到村级
                })
                resolve(res.data)
              }
            })
          }, 300)
        }
      },
      filters: {
        userName: '',
        nickName: '',
        orgId: null
      },
      userInfo: {
        userId: null,
        userName: '',
        nickName: '',
        bizScope: '',
        dataScope: '',
        ctrlData: ''
      },
      columns: [], // 在表格上显示的头信息,可能会存在动态过滤显示列表属性
      bizScopesOptions: [], // 角色业务范围选择项
      bizDataOptions: [], // 待授权的业务数据
      selectBizData: [], // 选中授权的业务数据
      assBizData: [], // 用户已经分配的业务类型及业务数据信息
      pageRequest: { pageNum: 1, pageSize: 10 }, // 分页请示参数信息, 当前页,每页大小
      pageResult: {}, // 分页查询结果集
      dialogVisible: false, // 新增和修改页面窗口打开标识
      objectId: null, // 对象主键ID,用户于修改时进行传递
      permsRule: {
        bizScope: [
          { required: true, message: '业务范围不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 加载各类选择项
    loadOptions: function() {
      // 加载业务范围选择项
      this.$api.dict.findDictByType('sys_role_biz_scope').then(res => {
        if (res.code === 200) {
          this.bizScopesOptions = res.data
        }
      })
      this.$api.org.getOrgByPid(0).then(res => {
        if (res.code === 200) {
          res.data.forEach(item => {
            item.children = []
          })
          this.orgOptions = res.data
          this.orgScopeOptions = res.data
        }
      })
    },
    /**
     * 分页查询数据
     * @param pageParams 分页请求参数
     */
    findPage: function(pageParams) {
      this.objectId = null
      if (pageParams !== null) {
        this.pageRequest = pageParams.pageRequest
      }
      this.pageRequest.Q_userName_S_LK = this.filters.userName
      this.pageRequest.Q_nickName_S_LK = this.filters.nickName
      this.pageRequest.orgId = this.filters.orgId
      this.$api.user
        .findPage(this.pageRequest)
        .then(res => {
          if (res.code === 200) {
            this.pageResult = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .then(pageParams != null ? pageParams.callback : '')
    },
    /***
     * 初始化列表显示
     */
    initColumns: function() {
      this.columns = [
        {
          prop: 'userName',
          label: '用户账号',
          sortable: false,
          minWidth: 100,
          type: 'success'
        },
        { prop: 'nickName', label: '用户名称', sortable: false, minWidth: 120 },
        { prop: 'orgNames', label: '所属机构', sortable: false, minWidth: 120 },
        { prop: 'roleNames', label: '角色', sortable: false, minWidth: 100 },
        { prop: 'phone', label: '手机', sortable: false, minWidth: 80 },
        // slot=true 使用自定义显示标签时，prop的对应值要与上面 slot 名称一样
        {
          prop: 'formatStatus',
          label: '状态',
          sortable: false,
          minWidth: 70,
          slot: true
        },
        {
          prop: 'optSolt',
          label: '操作',
          sortable: false,
          slot: true,
          minWidth: 260
        }
      ]
    },
    handleAdd: function() {
      this.dialogVisible = true
      this.objectId = null
    },
    // 删除操作
    handleDelete: function(row) {
      this.$confirm('确认删除选中记录吗？？', '提示', {}).then(() => {
        this.$api.user.deleteObj(row.userId).then(res => {
          if (res.code == 200) {
            this.$message.success('删除成功！')
            this.findPage(null)
          } else {
            this.$message.error('删除失败,' + res.msg)
          }
        })
      })
    },
    // 显示编辑界面
    handleEdit: function(row) {
      this.dialogVisible = true
      this.objectId = row.userId
    },
    // 设置数据访问权限
    handleDataPermission: function(row) {
      this.userInfo.bizScope = ''
      // 初始化默认选中第一个业务信息
      if (this.bizScopesOptions.length > 0) {
        this.userInfo.bizScope = this.bizScopesOptions[0].dictValue
      }
      this.userInfo.userId = row.userId
      // this.handleSelectBiz(this.userInfo.bizScope)
      this.userInfo.userName = row.userName
      this.userInfo.nickName = row.nickName
      this.dialogDataPermsVisible = true
    },
    // 选择业务类型时加载业务数据
    handleSelectBiz: function(val) {
      this.bizDataOptions = [] // 待授权的业务数据
      this.selectBizData = [] // 已经授权的业务数据
      // 从后台查询用户已经分配的业务数据信息
      this.$api.user.findBizScope(this.userInfo.userId, val).then(res => {
        if (res.code == 200) {
          if (res.data != null) {
            this.userInfo = res.data
            this.setOrgData(this.userInfo.ctrlData)
          }
          // 初始化业务数据
          this.initBizData(val)
        } else {
          this.$message.error('获取授权数据失败:' + res.msg)
        }
      })
    },
    // 数据授权页面初始化显示待选择业务数据
    initBizData(ctrlType) {
      this.bizDataOptions = [] // 待授权的业务数据
      this.selectBizData = [] // 已经授权的业务数据
      if (this.userInfo.dataScope == 5) {
        return
      }
      //获得当前业务对应的业务数据
      let obj = this.bizScopesOptions.find(item => {
        //model就是上面的数据源
        return item.dictValue === ctrlType //筛选出匹配数据
      })
      let bizUrl = obj.remark // 获得业务数据查询地址
      this.$api.role.findBizDataScope(bizUrl).then(res => {
        if (res.code == 200) {
          // 待分配的
          const unAssData = res.data
          for (let i = 0; i < unAssData.length; i++) {
            this.bizDataOptions.push({
              key: unAssData[i].key,
              label: unAssData[i].value
            })
          }
        } else {
          this.$message.error('获取授权数据失败:' + res.msg)
        }
      })
      this.selectBizData = this.userInfo.ctrlData.split(',')
    },
    // 选中部门
    orgSelectHandle: function(val) {
      // 部门ID
      this.filters.orgId = val[val.length - 1]
    },
    // 选中部门
    // eslint-disable-next-line no-unused-vars
    orgSelectScopeHandle: function(val) {
      let orgIds = ''
      for (let i = 0; i < this.selectedScopeOrgOptions.length; i++) {
        // 部门数据格式为:1,2,35，ID是按级排序，但量保存时只保存最后一个级别的ID就可以了
        let oids = this.selectedScopeOrgOptions[i]
        if (orgIds.length === 0) {
          orgIds = oids[oids.length - 1]
        } else {
          orgIds = orgIds + ',' + oids[oids.length - 1]
        }
      }
      this.userInfo.ctrlData = orgIds
    },
    // 根据 orgID获得org对象
    // eslint-disable-next-line no-unused-vars
    setOrgData(bizData) {
      var child3 = {
        orgName: '凌云县',
        orgId: 91,
        level: true,
        children: []
      }
      var child2 = {
        orgName: '百色市',
        orgId: 11,
        children: []
      }
      child2.children = child3
      var child1 = {
        orgName: '广西',
        orgId: 1,
        children: []
      }
      child1.children = child2
      this.selectedScopeOrgOptions.push(child1)
      this.selectedScopeOrgOptions.push(child2)
      this.selectedScopeOrgOptions.push(child3)
      // bizData.split(',').map(orgId => {
      //   this.$api.org.getObj(orgId).then(res => {
      //     if (res.code == 200) {
      //       let orgPath = res.data.path
      //       let oid = orgPath
      //         .substring(2, orgPath.length)
      //         .split(',')
      //         .map(value => {
      //           return parseInt(value)
      //         })
      //       this.selectedScopeOrgOptions.push(oid)
      //       console.log('初始加载机构')
      //       console.log(this.selectedScopeOrgOptions)
      //     }
      //   })
      // })
    },
    filterMethod: function(query, item) {
      return item.label.indexOf(query) > -1
    },
    // 保存分配的授权数据
    submitBizScope: function() {
      this.$refs['permsForm'].validate(valid => {
        if (valid) {
          // 把选中的业务信息id 使用逗号隔开进行组装
          let cData = this.userInfo.ctrlData
          if (this.userInfo.dataScope == '6') {
            cData = this.selectBizData.join(',')
          }
          let params = {
            userId: this.userInfo.userId,
            ctrlType: this.userInfo.bizScope,
            dataScope: this.userInfo.dataScope,
            ctrlData: cData
          }
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            this.$api.user.saveBizScope(params).then(res => {
              if (res.code == 200) {
                this.$message.success('授权成功！')
              } else {
                this.$message.error('授权失败,' + res.msg)
              }
            })
          })
        }
      })
    },
    renderContent: function(h, option) {
      return h('span', { domProps: { title: option.label } }, option.label)
    },
    // 设置可视区域高度
    initHeight: function() {
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          _this.tableHeight = window.innerHeight - 220
        })()
      }
    },
    handleItemChange(val) {
      let value = val[0]
      this.$api.org.getOrgByPid(value).then(res => {
        if (res.code === 200) {
          res.data.forEach(item => {
            item.children = []
          })
          this.orgOptions = res.data
        }
      })
    }
  },
  mounted() {
    _this = this
    this.initHeight()
    this.initColumns()
    this.loadOptions()
  },
  filters: {
    /**
     * 用户状态格式化
     * @param status 当前显示状态
     * @param type 1 获取内容，2 获得颜色
     * @returns {string|*} 格式化后结果
     */
    statusFilter(status, type) {
      const statusMap = {
        1: ['激活', 'success'],
        0: ['禁用', 'danger']
      }
      if (1 == type) {
        return statusMap[status][0]
      } else {
        return statusMap[status][1]
      }
    }
  },
  watch: {
    'filters.orgId': {
      handler() {
        // 选择部门时选中的部门ID,通过该参数的变化关闭部门选择框
        if (this.$refs.orgCascader) {
          this.$refs.orgCascader.dropDownVisible = false //监听值发生变化就关闭它
        }
      }
    }
  }
}
</script>

<style>
/**设置穿梭框的宽度**/
.el-transfer-panel {
  width: 35%;
}
</style>
