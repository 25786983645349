<template>
  <div class="page-container">
    <!--查询工具栏-->
    <div class="toolbar" style="float:left;padding-top:10px;padding-left:15px;">
      <el-form :inline="true" :model="filters" size="small">
        <el-form-item>
          <el-input
            v-model="filters.menuName"
            placeholder="菜单名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-search"
            label="查询"
            type="primary"
            @click="findTreeData(scope.row)"
          />
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-plus"
            label="新增"
            type="success"
            @click="handleAdd"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--表格树内容栏-->
    <el-table
      :data="tableTreeDdata"
      stripe
      size="mini"
      style="width: 100%;"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <table-tree
        prop="menuName"
        header-align="center"
        treeKey="menuId"
        width="150"
        label="名称"
      >
      </table-tree>
      <el-table-column header-align="center" align="center" label="图标">
        <template slot-scope="scope">
          <i :class="scope.row.icon || ''"></i>
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="类型"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === 0" size="small">目录</el-tag>
          <el-tag v-else-if="scope.row.type === 1" size="small" type="success"
            >菜单</el-tag
          >
          <el-tag v-else-if="scope.row.type === 2" size="small" type="info"
            >按钮</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="parentName"
        header-align="center"
        align="center"
        width="120"
        label="上级菜单"
      >
      </el-table-column>
      <el-table-column
        prop="path"
        header-align="center"
        align="center"
        width="150"
        :show-overflow-tooltip="true"
        label="菜单URL"
      >
      </el-table-column>
      <el-table-column
        prop="perms"
        header-align="center"
        align="center"
        width="150"
        :show-overflow-tooltip="true"
        label="授权标识"
      >
      </el-table-column>
      <el-table-column
        prop="sort"
        header-align="center"
        align="center"
        label="排序"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="left"
        width="185"
        label="操作"
      >
        <template slot-scope="scope">
          <base-button
            icon="fa fa-edit"
            label="编辑"
            type="primary"
            @click="handleEdit(scope.row)"
          />
          <base-button
            icon="fa fa-trash"
            label="删除"
            v-if="scope.row.children.length == 0"
            type="danger"
            @click="handleDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增修改界面 -->
    <el-dialog
      :title="!objectId ? '新增' : '修改'"
      width="40%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <!-- 表单 -->
      <menu-form
        :objectId="objectId"
        @close="dialogVisible = false"
        @reload="findTreeData"
      />
    </el-dialog>
  </div>
</template>

<script>
import TableTree from '@/components/TableTree' // 树型列表
import BaseButton from '@/components/BaseButton' // 公共按钮
import MenuForm from './MenuForm' // 菜单新增和编辑页面
export default {
  components: {
    TableTree,
    BaseButton,
    MenuForm
  },
  data() {
    return {
      size: 'small',
      loading: false,
      // 查询过滤条件
      filters: {
        menuName: ''
      },
      tableTreeDdata: [],
      dialogVisible: false, // 新增和修改页面窗口打开标识
      objectId: null // 对象主键ID,用户于修改时进行传递
    }
  },
  methods: {
    // 获取数据
    findTreeData: function() {
      this.loading = true
      this.$api.menu.findMenuTree().then(res => {
        this.tableTreeDdata = res.data
        this.loading = false
      })
    },
    // 显示新增界面
    handleAdd: function() {
      this.dialogVisible = true
      this.objectId = null
    },
    // 显示编辑界面
    handleEdit: function(row) {
      this.dialogVisible = true
      this.objectId = row.menuId
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确认删除选中记录吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$api.menu.deleteObj(row.menuId).then(res => {
          if (res.code === 200) {
            this.findTreeData()
            this.$message.success('删除成功')
          }
        })
      })
    }
  },
  mounted() {
    this.findTreeData()
  }
}
</script>

<style scoped></style>
