<template>
  <el-form
    :model="dataForm"
    :rules="dataRule"
    ref="dataForm"
    @keyup.enter.native="submitForm()"
    label-width="80px"
    size="small"
    style="text-align:left;"
  >
    <el-form-item label="菜单类型" prop="type">
      <el-radio-group v-model="dataForm.type">
        <el-radio
          v-for="(type, index) in menuTypeList"
          :label="index"
          :key="index"
          >{{ type }}</el-radio
        >
      </el-radio-group>
    </el-form-item>
    <el-form-item :label="menuTypeList[dataForm.type] + '名称'" prop="menuName">
      <el-input
        v-model="dataForm.menuName"
        :placeholder="menuTypeList[dataForm.type] + '名称'"
      ></el-input>
    </el-form-item>
    <el-form-item label="上级菜单" prop="parentName">
      <select-tree
        :data="treeData"
        :props="treeProps"
        :prop="
          dataForm.parentName == null || dataForm.parentName == ''
            ? '顶级菜单'
            : dataForm.parentName
        "
        :nodeKey="'' + dataForm.parentId"
        :currentChangeHandle="handleTreeSelectChange"
      >
      </select-tree>
    </el-form-item>
    <el-form-item v-if="dataForm.type !== 0" label="授权标识" prop="perms">
      <el-input
        v-model="dataForm.perms"
        placeholder="如: sys:user:add, sys:user:edit, sys:user:delete"
      ></el-input>
    </el-form-item>
    <el-form-item v-if="dataForm.type === 1" label="菜单路由" prop="url">
      <el-row>
        <el-col :span="22">
          <el-input v-model="dataForm.path" placeholder="菜单路由"></el-input>
        </el-col>
        <el-col :span="2" class="icon-list__tips">
          <el-tooltip placement="top" effect="light" style="padding: 10px;">
            <div slot="content">
              <p>URL格式：</p>
              <p>
                1.常规业务开发的功能URL，如用户管理，views目录下页面路径为
                /sys/User, 此处填写 /sys/user。
              </p>
              <p>
                2.嵌套外部网页，如通过菜单打开百度网页，此处填写
                http://www.baidu.com，http:// 不可省略。
              </p>
            </div>
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item v-if="dataForm.type !== 2" label="排序编号" prop="sort">
      <el-input-number
        v-model="dataForm.sort"
        controls-position="right"
        :min="0"
        label="排序编号"
      ></el-input-number>
    </el-form-item>
    <el-form-item v-if="dataForm.type !== 2" label="菜单图标" prop="icon">
      <el-row>
        <el-col :span="22">
          <el-input
            v-model="dataForm.icon"
            :readonly="false"
            placeholder="菜单图标名称（如：fa fa-home fa-lg）"
            class="icon-list__input"
          ></el-input>
        </el-col>
        <el-col :span="2" class="icon-list__tips">
          <fa-icon-tooltip />
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item>
      <el-button @click="closeWindow">取消</el-button>
      <el-button type="primary" @click="submitForm">提交</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import SelectTree from '@/components/SelectTree'
import FaIconTooltip from '@/components/FaIconTooltip' // 图标提示框
export default {
  name: 'dataForm',
  components: {
    SelectTree,
    FaIconTooltip
  },
  props: {
    objectId: Number // 在子组件中通过props接收编辑的对象主键
  },
  data() {
    return {
      operation: true, // true:新增, false:编辑
      menuTypeList: ['目录', '菜单', '按钮'],
      dataForm: {
        menuId: null,
        type: 1,
        menuName: '',
        parentId: 0,
        parentName: '',
        path: '',
        perms: '',
        sort: 0,
        icon: ''
      },
      dataRule: {
        menuName: [
          { required: true, message: '菜单名称不能为空', trigger: 'blur' }
        ]
      },
      treeData: [], // 下拉树菜单数据
      treeProps: {
        label: 'menuName',
        children: 'children'
      }
    }
  },
  watch: {
    // 通过watch函数监控objectId的变化，把值copy到本地的对象
    objectId: {
      immediate: true, // 很重要！！！ 开启首次赋值监听
      deep: true,
      handler(val) {
        if (val == null) {
          this.resetForm()
          return
        }
        this.findMenu(val)
      }
    }
  },
  methods: {
    // 获取数据
    findTreeData: function() {
      this.$api.menu.findMenuTree().then(res => {
        let parent = {
          parentId: 0,
          menuName: '顶级菜单',
          children: res.data
        }
        this.treeData = [parent]
      })
    },
    // 菜单树选中
    handleTreeSelectChange(data) {
      this.dataForm.parentId = data.menuId
      this.dataForm.parentName = data.menuName
    },
    // 表单提交
    submitForm: function() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.$api.menu
            .save(this.dataForm, this.operation ? 'post' : 'put')
            .then(res => {
              if (res.code == 200) {
                this.$message.success('操作成功！')
                this.$emit('reload') // 更新菜单列表信息
                this.closeWindow()
              } else {
                this.$message.error('操作失败,' + res.msg)
              }
            })
        }
      })
    },
    /**
     * 根据菜单ID查询菜单信息
     * @param id
     */
    findMenu: function(id) {
      this.$api.menu.getObjById(id).then(res => {
        if (res.code == 200) {
          this.dataForm = res.data
          this.operation = false
        } else {
          this.$message.error('查询失败,' + res.msg)
        }
      })
    },
    // 关闭新增修改窗口
    closeWindow: function() {
      this.resetForm()
      this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
    },
    // 重置表单信息
    resetForm: function() {
      this.dataForm = {
        menuId: null,
        type: 1,
        menuName: '',
        parentId: 0,
        parentName: '',
        path: '',
        perms: '',
        sort: 0,
        icon: ''
      }
    }
  },
  mounted() {
    this.findTreeData()
  }
}
</script>

<style scoped></style>
