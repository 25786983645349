<template>
  <el-form
    :model="dataForm"
    :rules="dataRule"
    ref="dataForm"
    label-width="80px"
    size="small"
    style="text-align:left;"
  >
    <el-form-item label="上级菜单" prop="parentName">
      <select-tree
        :data="treeData"
        :props="treeProps"
        :prop="
          dataForm.parentName == null || dataForm.parentName === ''
            ? '顶级菜单'
            : dataForm.parentName
        "
        :nodeKey="'' + dataForm.parentId"
        :currentChangeHandle="handleTreeSelectChange"
      >
      </select-tree>
    </el-form-item>
    <el-form-item label="标题" prop="docTitle">
      <el-input v-model="dataForm.docTitle" placeholder="请输入标题"></el-input>
    </el-form-item>
    <el-form-item label="排序编号" prop="sort">
      <el-input-number
        v-model="dataForm.sort"
        controls-position="right"
        :min="1"
        label="排序编号"
      ></el-input-number>
    </el-form-item>
    <el-form-item label="内容">
      <div style="border: 1px solid #ccc;">
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          style="height: 500px; overflow-y: hidden;"
          v-model="dataForm.docContent"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="onCreated"
        />
      </div>
    </el-form-item>
    <el-form-item>
      <el-button @click="closeWindow">取消</el-button>
      <el-button type="primary" @click="submitForm" :loading="editLoading"
        >提交</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import SelectTree from '@/components/SelectTree'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
let _this = null // 定义一个全局能调用的this
export default {
  name: 'dataForm',
  components: {
    Editor,
    Toolbar,
    SelectTree
  },
  props: {
    objectId: Number // 在子组件中通过props接收编辑的对象主键
  },
  data() {
    return {
      editor: null,
      toolbarConfig: {},
      editorConfig: { MENU_CONF: {} },
      mode: 'default', // or 'simple'
      dataForm: {
        docId: null,
        docTitle: '', // 标题
        docContent: '', // 内容
        docType: 1, // 操作指南类型
        parentId: 0,
        parentName: '',
        sort: 1
      },
      treeData: [], // 下拉树菜单数据
      treeProps: {
        label: 'docTitle',
        children: 'children'
      },
      dataRule: {
        docTitle: [{ required: true, message: '标题不能为空', trigger: 'blur' }]
      },
      editLoading: false
    }
  },
  watch: {
    // 通过watch函数监控objectId的变化，把值copy到本地的对象
    objectId: {
      immediate: true, // 很重要！！！ 开启首次赋值监听
      deep: true,
      handler(val) {
        if (val == null) {
          this.resetForm()
          return
        }
        this.findObj(val)
      }
    }
  },
  methods: {
    // 获取数据
    findTreeData: function() {
      this.$api.biogas.findDocHelpTree({}).then(res => {
        let parent = {
          parentId: 0,
          docTitle: '顶级菜单',
          children: res.data
        }
        this.treeData = [parent]
      })
    },
    // 表单提交
    submitForm: function() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.editLoading = true
          let newDocContent = this.formatEditorText(this.dataForm.docContent)
          this.dataForm.docContent = newDocContent
          this.$api.biogas.saveDoc(this.dataForm).then(res => {
            this.editLoading = false
            if (res.code == 200) {
              this.$message.success('操作指南提交成功！')
              this.$emit('reload') //列表信息
              this.closeWindow()
            } else {
              this.$message.error('操作失败,' + res.msg)
            }
          })
        }
      })
    },

    /**
     * 处理富文本里的图片宽度自适应
     * 1.去掉img标签里的style、width、height属性
     * 2.img标签添加style属性：max-width:100%;height:auto
     * @param html
     * @returns {void|string|*}
     */
    formatEditorText(html) {
      // eslint-disable-next-line no-unused-vars
      let str = html.replace(/<img[^>]*>/gi, function(match, capture) {
        return match.replace(
          /style\s*?=\s*?([‘"])[\s\S]*?\1/gi,
          'style="max-width:100%;height:auto;"'
        ) // 替换style
      })
      return str
    },
    /**
     * 根据对象ID查询对象信息
     * @param id
     */
    findObj: function(id) {
      this.$api.biogas.findDocById(id).then(res => {
        if (res.code == 200) {
          this.dataForm = Object.assign({}, res.data)
        } else {
          this.$message.error('查询失败,' + res.msg)
        }
      })
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    createEditorConfig() {
      this.editorConfig.placeholder = '亲，请输入内容...'
      this.editorConfig.MENU_CONF['uploadImage'] = {
        // 自定义上传图片,调用自己上传方法上传内容图片信息
        customUpload(file, insertFn) {
          let form = new FormData()
          form.append('file', file)
          _this.$api.biogas.uploadEditorImg(form).then(res => {
            if (res.code == 200) {
              insertFn(res.data.filePath, res.data.fileName, res.data.filePath)
            } else {
              _this.$message.error(res.msg)
            }
          })
        }
      }
    },
    // 菜单树选中
    handleTreeSelectChange(data) {
      this.dataForm.parentId = data.docId
      this.dataForm.parentName = data.docTitle
    },
    // 关闭新增修改窗口
    closeWindow: function() {
      this.resetForm()
      this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
    },
    // 重置表单信息
    resetForm: function() {
      this.objectId = null
      this.dataForm = {
        docId: null,
        docTitle: '', // 标题
        docContent: '', // 内容
        docType: 1, // 操作指南类型
        parentId: 0,
        parentName: '',
        sort: 1
      }
    }
  },
  created() {
    _this = this
    this.createEditorConfig()
    this.findTreeData()
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped></style>
