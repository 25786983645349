<template>
  <div class="page-container">
    <!--工具栏-->
    <div class="toolbar" style="float:left;padding-top:10px;padding-left:15px;">
      <el-form
        :inline="true"
        :model="filters"
        :size="size"
        style="text-align:left;"
      >
        <el-form-item>
          <el-select
            v-model="cityValue"
            placeholder="请选择市"
            @change="chooseCity"
            style="width: 150px;"
            :disabled="cityDisabled"
          >
            <el-option
              v-for="item in cityList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="countyValue"
            placeholder="请选择区/县"
            @change="chooseCounty"
            :disabled="countyDisabled"
          >
            <el-option
              v-for="item in countyList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="streetValue"
            placeholder="请选择乡/镇"
            @change="chooseStreet"
            style="width: 150px;"
            :disabled="streetDisabled"
          >
            <el-option
              v-for="item in streetList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="villageValue"
            placeholder="请选择村"
            @change="chooseVillage"
            style="width: 150px;"
          >
            <el-option
              v-for="item in villageList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="filters.tun"
            placeholder="屯名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-search"
            label="查询"
            type="primary"
            @click="findPage(null)"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--表格内容栏-->
    <base-table
      :data="pageResult"
      :columns="columns"
      :maxHeight="tableHeight"
      :showOperation="showOperation"
      @findPage="findPage"
    >
      <template slot="optSolt" slot-scope="scope">
        <base-button
          icon="fa fa-address-card"
          label="查看"
          size="mini"
          type="primary"
          perms="biogas:publicity:view"
          @click="handleView(scope.row)"
        />
      </template>
    </base-table>
    <!-- 核验项目审核界面 -->
    <el-dialog
      title="安全宣传材料验详细信息"
      width="70%"
      :visible.sync="dialogViewVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <publicity-view :objectId="objectId" @close="dialogViewVisible = false" />
    </el-dialog>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable' // 树型列表
import BaseButton from '@/components/BaseButton' // 公共按钮
import PublicityView from './PublicityView'
import Cookies from 'js-cookie'
let _this = null // 定义一个全局能调用的this
export default {
  name: 'publicity',
  components: {
    BaseTable,
    BaseButton,
    PublicityView
  },
  data() {
    return {
      size: 'small', // 样式图标大小
      showOperation: false, // 不显示模板的操作状态栏
      showSelection: true,
      cityList: [], // 市列表
      countyList: [], // 区/县列表
      streetList: [], // 乡/镇列表
      villageList: [], // 村列表
      cityValue: '', // 选中的市
      countyValue: '', // 选中的区县
      streetValue: '', // 选中的乡/镇
      villageValue: '', // 选中的村
      cityDisabled: false, //市下拉框禁止选择
      countyDisabled: false, //市下拉框禁止选择
      streetDisabled: false, //市下拉框禁止选择
      tableHeight: window.innerHeight - 220, // 表格的高度
      filters: {
        projectName: '',
        verName: '',
        tun: '',
        lastHisUseStatus: null
      },
      columns: [], // 初始表头信息
      pageRequest: { pageNum: 1, pageSize: 10 }, // 分页请示参数信息, 当前页,每页大小
      pageResult: {}, // 分页查询结果集
      dialogViewVisible: false, //查看详细页面窗口打开标识
      objectId: null // 对象主键ID,用户于修改时进行传递
    }
  },
  methods: {
    initLoad() {
      // 加载机构下拉框
      this.$api.org.getOrgByPid(1).then(resp => {
        if (resp.code === 200) {
          this.cityList = resp.data
        } else {
          this.$message.error(resp.msg)
        }
      })
    },
    /**
     * 分页查询数据
     * @param pageParams 分页请求参数
     */
    findPage: function(pageParams) {
      if (pageParams !== null) {
        this.pageRequest = pageParams.pageRequest
      }
      this.pageRequest.orgId = this.filters.orgId
      this.pageRequest.tun = this.filters.tun
      this.$api.publicity
        .findPublicityPage(this.pageRequest)
        .then(res => {
          if (res.code === 200) {
            this.pageResult = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .then(pageParams != null ? pageParams.callback : '')
    },
    /***
     * 初始化列表显示
     */
    initColumns: function() {
      this.columns = [
        {
          prop: 'address',
          label: '宣传地区',
          sortable: false,
          minWidth: 180
        },
        {
          prop: 'publicityTime',
          label: '宣传时间',
          sortable: false,
          minWidth: 80
        },
        {
          prop: 'createName',
          label: '发布者',
          sortable: false,
          minWidth: 60
        },
        {
          prop: 'createTime',
          label: '发布时间',
          sortable: false,
          minWidth: 120
        },
        {
          prop: 'optSolt',
          label: '操作',
          sortable: false,
          slot: true,
          minWidth: 90
        }
      ]
    },
    // 初始化区域选中信息
    initSelectOrg() {
      // 获取当前登录用户的机构信息
      // 因为区域选择框是市级开始，而且路径等是从 省级->市级->区级->乡级->村级
      let orgPath = Cookies.get('user_orgIds')
      // 分割路径ID
      let orgIds = orgPath.split(',')
      if (orgIds.length === 2) {
        // 当前账号到市级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.cityDisabled = true
      }
      // 当前账号到县级
      if (orgIds.length === 3) {
        // 只到区级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[2])
        this.chooseCounty(this.countyValue)
        this.cityDisabled = true
        this.countyDisabled = true
      }
      // 当前账号的机构到乡镇级
      if (orgIds.length === 4) {
        // 只到乡级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[2])
        this.chooseCounty(this.countyValue)
        this.streetValue = parseInt(orgIds[3])
        this.chooseStreet(this.streetValue)
        this.cityDisabled = true
        this.countyDisabled = true
        this.streetDisabled = true
      }
      if (orgIds.length === 5) {
        // 村级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[2])
        this.chooseCounty(this.countyValue)
        this.streetValue = parseInt(orgIds[3])
        this.chooseStreet(this.streetValue)
        this.villageValue = parseInt(orgIds[4])
        this.cityDisabled = true
        this.countyDisabled = true
        this.streetDisabled = true
      }
    },
    // 设置可视区域高度
    initHeight: function() {
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          _this.tableHeight = window.innerHeight - 220
        })()
      }
    },
    formatType(row) {
      let typeStr = ''
      this.typeList.forEach(item => {
        if (item.dictValue == String(row.projectType)) {
          typeStr = item.dictLabel
        }
      })
      return typeStr
    },
    //显示详细页面
    handleView(row) {
      this.dialogViewVisible = true
      this.objectId = row.publicityId
    },
    /**选中市**/
    chooseCity: function(value) {
      this.countyList = []
      this.countyValue = ''
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
      this.filters.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.countyList = resp.data
        }
      })
    },
    /**选中区县**/
    chooseCounty: function(value) {
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
      this.filters.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.streetList = resp.data
        }
      })
    },
    /**选中乡/镇**/
    chooseStreet: function(value) {
      this.villageList = []
      this.villageValue = ''
      this.filters.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.villageList = resp.data
        }
      })
    },
    /**选中村**/
    chooseVillage: function(value) {
      this.filters.orgId = value
    }
  },
  mounted() {
    _this = this
    this.initHeight()
    this.initColumns()
  },
  created() {
    this.initLoad()
    this.initSelectOrg()
  }
}
</script>

<style>
/* 顶部下划线 */
.dialog-mask .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
.dialog-mask .el-dialog__body {
  padding: 10px 20px;
}
</style>
