<template>
  <div class="detail__wrap">
    <div class="detail__wrap--header">
      <div class="detail__wrap--title">
        户用沼气池基本信息
      </div>
    </div>
    <el-collapse-transition>
      <div class="detail__content">
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                户主姓名
              </div>
              <div class="content__item--message">
                {{ form.projectName }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                建设项目来源
              </div>
              <div class="content__item--message">
                {{ getSelectOrValue('projectTypeSelector', form.projectType) }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                地址
              </div>
              <div class="content__item--message">
                {{ form.address }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                户主电话
              </div>
              <div class="content__item--message">
                {{ form.phone }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                户主身份证
              </div>
              <div class="content__item--message">
                {{ form.idCard }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                建池时间
              </div>
              <div class="content__item--message">
                {{ form.buildDate }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                数据来源
              </div>
              <div class="content__item--message">
                {{ form.dataSource == 1 ? '档案数据' : '核验新增' }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                经度
              </div>
              <div class="content__item--message">
                {{ form.longitude }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                纬度
              </div>
              <div class="content__item--message">
                {{ form.latitude }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                建池技工
              </div>
              <div class="content__item--message">
                {{ form.builder }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                建池编号
              </div>
              <div class="content__item--message">
                {{ form.biogasNo }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                项目规模（m³）
              </div>
              <div class="content__item--message">
                {{ form.projectSize }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                项目创建时间
              </div>
              <div class="content__item--message">
                {{ form.createTime }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                是否联户
              </div>
              <div class="content__item--message">
                {{ form.lhStatus === 1 ? '是' : '否' }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                联户户主
              </div>
              <div class="content__item--message">
                {{ form.lhNames }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                核验年度
              </div>
              <div class="content__item--message">
                {{ form.batchId }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                核验状态
              </div>
              <div class="content__item--message">
                {{ getVerStatus(form.verStatus) }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-collapse-transition>

    <div class="detail__wrap--header" style="margin-top: 20px;">
      <div class="detail__wrap--title">
        户用沼气池核验信息
      </div>
    </div>
    <el-collapse-transition>
      <div class="detail__content">
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                一池三改
              </div>
              <div class="content__item--message">
                {{ form.projectVer.isOneThree == 1 ? '是' : '否' }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                使用情况
              </div>
              <div class="content__item--message">
                {{
                  getSelectOrValue(
                    'useStatusSelector',
                    form.projectVer.useStatus
                  )
                }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                安全隐患
              </div>
              <div class="content__item--message">
                {{ getSelectOrValue('unSafeSelector', form.projectVer.unSafe) }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="form.projectVer.useStatus == 2">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                停用原因
              </div>
              <div class="content__item--message">
                {{
                  getSelectOrValue(
                    'stopReasonSelector',
                    form.projectVer.statusReason
                  )
                }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                停用时间
              </div>
              <div class="content__item--message">
                {{ form.projectVer.statusDate }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24" v-if="form.projectVer.useStatus == 2">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                停用拟处置方式
              </div>
              <div class="content__item--message">
                {{
                  getSelectOrValue('stopWaySelector', form.projectVer.statusWay)
                }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="form.projectVer.useStatus == 3">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                报废原因
              </div>
              <div class="content__item--message">
                {{
                  getSelectOrValue(
                    'scrapReasonSelector',
                    form.projectVer.statusReason
                  )
                }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                报废时间
              </div>
              <div class="content__item--message">
                {{ form.projectVer.statusDate }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24" v-if="form.projectVer.useStatus == 3">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                报废依据
              </div>
              <div class="content__item--message">
                {{
                  getSelectOrValue(
                    'scrapWaySelector',
                    form.projectVer.statusWay
                  )
                }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24" v-if="form.projectVer.useStatus == 3">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                报废承拆单位
              </div>
              <div class="content__item--message">
                {{ form.projectVer.demolishUnit }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                手机定位地址
              </div>
              <div class="content__item--message">
                {{ form.projectVer.lbsAddr }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                核验人
              </div>
              <div class="content__item--message">
                {{ form.projectVer.verName }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                核验提交时间
              </div>
              <div class="content__item--message">
                {{ form.projectVer.verTime }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="form.verStatus == 3">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                审核人
              </div>
              <div class="content__item--message">
                {{ form.projectVer.approveUserName }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                归档时间
              </div>
              <div class="content__item--message">
                {{ form.projectVer.finishTime }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                无信号核验
              </div>
              <div class="content__item--message">
                {{ form.projectVer.sbmtType == 3 ? '是' : '否' }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                核验照片
              </div>
              <div class="content__item--message" style="text-align: left;">
                <el-upload
                  action="#"
                  class="disUoloadSty"
                  list-type="picture-card"
                  :limit="3"
                  :auto-upload="false"
                  :file-list="smallImgList"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.smallPath"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-image-viewer
                  v-if="showViewer"
                  z-index="9999"
                  :on-close="closeViewer"
                  :url-list="getViewer()"
                />
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                整改通知书照片
              </div>
              <div class="content__item--message" style="text-align: left;">
                <el-upload
                  action="#"
                  class="disUoloadSty"
                  list-type="picture-card"
                  :limit="1"
                  :auto-upload="false"
                  :file-list="rectificationSmallImgList"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.smallPath"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-image-viewer
                  v-if="showViewer"
                  z-index="9999"
                  :on-close="closeViewer"
                  :url-list="getViewer()"
                />
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="form.projectVer.isPending === 1">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
              style="color: red"
            >
              <div class="content__item--label">
                是否待查项目
              </div>
              <div class="content__item--message" style="color: red">
                {{ form.projectVer.isPending === 1 ? '待查项目' : '正常核验' }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
              style="color: red"
            >
              <div class="content__item--label">
                待查原因
              </div>
              <div class="content__item--message" style="color: red">
                {{
                  getSelectOrValue(
                    'pendingReason',
                    form.projectVer.pendingReason
                  )
                }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                核验说明
              </div>
              <div class="content__item--message">
                <el-input
                  type="textarea"
                  :rows="2"
                  :disabled="true"
                  v-model="form.projectVer.remark"
                ></el-input>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="form.verStatus == 3">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                审核意见
              </div>
              <div class="content__item--message">
                <el-input
                  type="textarea"
                  :rows="2"
                  :disabled="true"
                  v-model="form.projectVer.approveOpn"
                ></el-input>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-collapse-transition>
    <div v-if="form.verStatus === 3 && form.projectVer.useStatus === 4">
      <div class="detail__wrap--header" style="margin-top: 20px;">
        <div class="detail__wrap--title">
          户用沼气池报废材料
        </div>
      </div>
      <el-table
        :data="scrapFiles"
        border
        style="width: 100%"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="fileName" label="文件名称" width="440">
        </el-table-column>
        <el-table-column prop="fileSize" label="文件大小" width="160">
        </el-table-column>
        <el-table-column prop="createTime" label="上传时间" width="200">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              @click="handleDownload(scope.row)"
              type="text"
              size="small"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-form
      :model="approveForm"
      :rules="dataRule"
      ref="dataForm"
      style="margin-top: 20px;"
      v-if="form.verStatus === 2 && this.isBack === 1"
    >
      <div class="detail__wrap--header">
        <div class="detail__wrap--title">
          审核退回
        </div>
      </div>
      <div class="detail__content">
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                退回意见
              </div>
              <div class="content__item--message">
                <el-form-item prop="approveOpn">
                  <el-input
                    type="textarea"
                    :rows="2"
                    v-model="approveForm.approveOpn"
                    placeholder="请输入退回意见说明"
                  ></el-input>
                </el-form-item>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 15px;text-align: center;">
        <el-button
          type="success"
          @click="handleHisView(form.projectId)"
          v-if="form.lastHisBatchId < form.batchId"
          >查看历史核验信息</el-button
        >
        <el-button type="primary" @click="submitForm" :loading="editLoading"
          >退 回</el-button
        >
      </div>
    </el-form>
    <el-form
      :model="recheckForm"
      style="margin-top: 20px;"
      v-if="getRecheckVisible(form)"
    >
      <div class="detail__wrap--header">
        <div class="detail__wrap--title">
          沼气设施开启巡查
        </div>
      </div>
      <div class="detail__content">
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                巡查启动说明
              </div>
              <div
                class="content__item--message"
                style="text-align: left;font-size: 12px;"
              >
                1.
                2021年度核验情况为“停用”的沼气设施在2022年暂时不需要巡查，若需要启动巡查通过单独启动方式进行启动巡查；<br />
                2. 巡查启动成功后，可通知核验员在APP端进行核验。<br />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div style="margin-top: 15px;text-align: center;">
        <el-button
          type="primary"
          @click="submitRecheck"
          :loading="recheckLoading"
          >启动巡查</el-button
        >
      </div>
    </el-form>
    <div
      style="margin-top: 15px;text-align: center;"
      v-if="this.isBack != 1 && form.lastHisBatchId < form.batchId"
    >
      <el-button type="success" @click="handleHisView(form.projectId)"
        >查看历史核验信息</el-button
      >
    </div>
    <el-dialog
      title="户用沼气池历史核验信息"
      width="70%"
      :append-to-body="true"
      :visible.sync="dialogHisViewVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <household-his-view
        :objectId="projectId"
        @close="dialogHisViewVisible = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { hasPermission } from '@/permission/index.js'
let _this = null // 定义一个全局能调用的this
export default {
  name: '户用沼气池设施详细信息页面',
  components: {
    ElImageViewer
  },
  props: {
    objectId: Number, // 在子组件中通过props接收编辑的对象主键
    isBack: Number // 是否显示审核退回功能模块，1 显示
  },
  data() {
    return {
      vertical: false,
      editLoading: false,
      recheckLoading: false,
      selectorList: [], // 公共选项
      smallImgList: [], //缩略图地址
      imgList: [], // 大图地址
      rectificationSmallImgList: [], // 整改通知缩略图地址
      rectificationImgList: [], // 整改通知大图地址
      typeId: 1, // 图片类型，1-核验图片，2-整改通知书图片
      showViewer: false, // 显示大图
      imgIndex: 0, // 点击查看哪张图片
      scrapFiles: [], // 报废文件信息
      dialogHisViewVisible: false, //查看历史核验页面窗口打开标识
      projectId: null, // 查看历史记录的项目ID
      form: {}, // 页面显示表单信息
      approveForm: {
        projectId: null,
        verId: null,
        approveStatus: 3,
        approveOpn: ''
      }, // 审核表单信息
      recheckForm: {
        projectIds: null
      }, // 启动巡查表单
      dataRule: {
        approveOpn: [
          { required: true, message: '请填写退回意见', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    // 通过watch函数监控objectId的变化，把值copy到本地的对象
    objectId: {
      immediate: true, // 很重要！！！ 开启首次赋值监听
      deep: true,
      handler(val) {
        if (val == null) {
          return
        }
        this.findObj(val)
      }
    }
  },
  methods: {
    //初始化加载
    initLoad() {
      // 加载公共选择项
      this.$api.biogas.getSelector(1).then(res => {
        if (res.code === 200) {
          this.selectorList = res.data
        }
      })
    },
    /**
     * 根据ID查询对象信息
     * @param id
     */
    findObj: function(id) {
      this.smallImgList = []
      this.imgList = []
      this.rectificationSmallImgList = [] // 整改通知缩略图地址
      this.rectificationImgList = [] // 整改通知大图地址
      this.resetForm()
      this.$api.biogas.getPojectInfo(id).then(res => {
        if (res.code == 200) {
          this.form = Object.assign({}, res.data)
          // 拼接得到图片信息对象
          let imgs = this.form.imgs
          for (let i = 0; i < imgs.length; i++) {
            let obj = {
              id: i,
              smallPath: imgs[i].smallPath
            }
            this.smallImgList.push(obj)
            // 将图片信息对象，push到imgList数组中
            this.imgList.push(imgs[i].imgPath)
          }
          let rectificationImgs = this.form.rectificationImgs
          for (let i = 0; i < rectificationImgs.length; i++) {
            let obj = {
              id: i,
              typeId: 2,
              smallPath: rectificationImgs[i].smallPath
            }
            this.rectificationSmallImgList.push(obj)
            // 将图片信息对象，push到imgList数组中
            this.rectificationImgList.push(rectificationImgs[i].imgPath)
          }
          this.scrapFiles = this.form.scrapFiles
        } else {
          this.$message.error('查询失败,' + res.msg)
        }
      })
    },
    // 组装如项目类型、停用原因等选项值
    getSelectOrValue(keyType, key) {
      let keyArry = String(key).split(',')
      let str = ''
      let kvList = this.selectorList[keyType]
      kvList.forEach(item => {
        for (let i = 0; i < keyArry.length; i++) {
          if (item.key == String(keyArry[i])) {
            str = str + '  ' + item.value
          }
        }
      })
      return str.trim()
    },
    // 显示图片大图
    handlePictureCardPreview(file) {
      this.typeId = file.typeId
      this.imgIndex = file.id
      this.showViewer = true
    },
    // 关闭预览大图
    closeViewer() {
      this.showViewer = false
    },
    getViewer() {
      let tempImgList = [...this.imgList] //所有图片地址
      if (this.typeId === 2) {
        tempImgList = [...this.rectificationImgList]
      }
      if (this.imgIndex === 0) {
        return tempImgList
      }
      // 调整图片顺序，把当前图片放在第一位
      let start = tempImgList.splice(this.imgIndex)
      let remain = tempImgList.splice(0, this.imgIndex)
      return start.concat(remain) //将当前图片调整成点击缩略图的那张图片
    },
    // 下载文件
    handleDownload(file) {
      // 从url中找到文件名的索引位置
      const filePath = file.filePath
      if (file.fileName.lastIndexOf('.pdf') === -1) {
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = filePath
        link.setAttribute('download', file.fileName)
        document.body.appendChild(link)
        link.click()
      } else {
        window.open(filePath, file.fileName)
      }
    },
    // 关闭新增修改窗口
    closeWindow: function() {
      this.$emit('reload') //列表信息
      this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
    },
    //显示详细页面
    handleHisView(projectId) {
      let params = projectId
      let route = _this.$router.resolve({
        path: '/biogas/HouseholdHisView',
        query: {
          params
        }
      })
      window.open(route.href, '_blank')
    },
    // 重置表单信息
    resetForm() {
      this.approveForm = {
        projectId: null,
        verId: null,
        approveStatus: 3,
        approveOpn: ''
      }
    },
    // 表单提交
    submitForm: function() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.editLoading = true
          this.approveForm.verId = this.form.projectVer.verId
          this.approveForm.projectId = this.form.projectId
          this.$api.biogas.submitApprove(this.approveForm).then(res => {
            this.editLoading = false
            if (res.code == 200) {
              this.$message.success('亲，项目退回成功，请通知核验员重新核验！')
              this.closeWindow()
            } else {
              this.$message.error('操作失败,' + res.msg)
            }
          })
        }
      })
    },
    getVerStatus(verState) {
      if (verState === -1) {
        return '审核退回'
      }
      if (verState === 1) {
        return '未核验'
      }
      if (verState === 2) {
        return '审核中'
      }
      if (verState === 3) {
        return '已归档'
      }
      if (verState === 4) {
        return '待上传报废材料'
      }
    },
    // 是否巡查启动信息
    getRecheckVisible(obj) {
      let visible = false
      if (
        hasPermission('biogas:project:recheck') &&
        obj.batchId === 2021 &&
        obj.projectVer.useStatus === 2
      ) {
        visible = true
      }
      return visible
    },
    submitRecheck() {
      this.$confirm('确定要对该沼气池设施启动巡查吗？', '提示', {}).then(() => {
        this.editLoading = true
        this.recheckForm.projectIds = this.form.projectId
        this.$api.biogas.assignProjectRecheck(this.recheckForm).then(res => {
          this.editLoading = false
          if (res.code === 200) {
            this.$message.success(
              '亲，项目巡查启动成功，请通知核验员进行巡查核验！'
            )
            this.closeWindow()
          } else {
            this.$message.error('操作失败,' + res.msg)
          }
        })
      })
    }
  },
  mounted() {
    _this = this
    this.initLoad()
  }
}
</script>

<style lang="less">
.detail__wrap {
  background: #fff;
  border-radius: 2px;
  .detail__wrap--header {
    display: flex;
    height: 32px;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .detail__wrap--title {
      display: inline-block;
      font-size: 18px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      margin-left: 10px;
      &:after {
        content: '';
        width: 3px;
        height: 100%;
        background: #2d8cf0;
        border-radius: 2px;
        position: absolute;
        top: 1px;
        left: -10px;
      }
    }
  }
  .detail__content {
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    .detail__content--flex {
      display: flex;
      height: 100%;
    }
    .content__item--label {
      font-size: 14px;
      padding: 8px 16px;
      width: 150px;
      text-align: left;
      background-color: #fafafa;
      border-right: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
    .content__item--message {
      flex: 1;
      font-size: 14px;
      padding: 8px 16px;
      line-height: 20px;
      color: #606266;
      width: 100%;
      border-right: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
