<template>
  <div>
    <el-upload
      drag
      ref="uploadFiles"
      :limit="limitNum"
      :auto-upload="false"
      :accept="accept"
      action="#"
      :before-upload="beforeUploadFile"
      :on-change="fileChange"
      :on-exceed="exceedFile"
      :file-list="fileList"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">
        只能上传后缀是.doc,.docx,.png,.pdf,.jpg,.rar,.zip的文件，且不超过50M
      </div>
    </el-upload>
    <br />
    <el-button
      size="small"
      type="primary"
      :loading="loginLoading"
      @click="uploadFile"
      >立即上传</el-button
    >
  </div>
</template>

<script>
export default {
  name: '沼气设施报废材料上传',
  props: {
    projectIds: String //上传材料的项目ID信息
  },
  data() {
    return {
      loginLoading: false,
      limitNum: 5, // 上传文件时，同时允许上传的最大数
      accept: '.doc,.docx,.png,.pdf,.jpg,.rar,.zip', // 允许上传文件类型
      form: {
        file: ''
      },
      fileList: [] // 文件列表
    }
  },
  methods: {
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.$message.warning(
        `只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length +
          fileList.length} 个`
      )
    },
    // fileList 是文件列表发生变化后，返回的修改后的列表对象，这里直接赋值就好啦！
    // eslint-disable-next-line no-unused-vars
    fileChange(file, fileList) {
      this.fileList = fileList
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUploadFile(file) {
      let extension = file.name
        .substring(file.name.lastIndexOf('.') + 1)
        .toLocaleLowerCase()
      const isAcceptFiles =
        extension === 'doc' ||
        extension === 'docx' ||
        extension === 'png' ||
        extension === 'pdf' ||
        extension === 'jpg' ||
        extension === 'rar' ||
        extension === 'zip'
      let size = file.size / 1024 / 1024
      if (!isAcceptFiles) {
        this.$message.warning(
          '只能上传后缀是.doc,.docx,.png,.pdf,.jpg,.rar,.zip的文件'
        )
      }
      if (size > 50) {
        this.$message.warning('文件大小不得超过50M')
      }
    },
    uploadFile() {
      if (this.fileList.length === 0) {
        this.$message.warning('请上传文件')
      } else {
        this.loginLoading = true
        let form = new FormData()
        this.fileList.forEach(file => {
          form.append('files', file.raw)
        })
        form.append('projectIds', this.projectIds)
        this.$api.biogas.uploadProjectMaterial(form).then(res => {
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.$emit('reload') //列表信息
            this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
          } else {
            this.$message.error(res.msg)
          }
          this.fileList = []
          this.$refs.uploadFiles.clearFiles()
          this.loginLoading = false
        })
      }
    }
  }
}
</script>

<style scoped></style>
