<template>
  <el-form
    :model="dataForm"
    :rules="dataRule"
    ref="dataForm"
    label-width="100px"
    size="small"
    style="text-align:left;"
  >
    <el-form-item label="标题" prop="docTitle">
      <el-input v-model="dataForm.docTitle" placeholder="请输入标题"></el-input>
    </el-form-item>
    <el-form-item label="公告类型" prop="docType">
      <el-radio-group v-model="dataForm.docType">
        <el-radio border label="2">通知</el-radio>
        <el-radio border label="3">公告</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="发送对象类型" prop="noticeType">
      <el-radio-group v-model="dataForm.noticeType">
        <el-radio border label="1">核验员</el-radio>
        <el-radio border label="2">县级</el-radio>
        <el-radio border label="3">市级</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="发布者" prop="publishName">
      <el-input
        v-model="dataForm.publishName"
        placeholder="请输入发布者姓名"
      ></el-input>
    </el-form-item>
    <el-form-item label="排序编号" prop="sort">
      <el-input-number
        v-model="dataForm.sort"
        controls-position="right"
        :min="1"
        label="排序编号"
      ></el-input-number>
      <el-link type="warning" disabled>排序号越高排序越靠前</el-link>
    </el-form-item>
    <el-form-item label="内容">
      <div style="border: 1px solid #ccc;">
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          style="height: 500px; overflow-y: hidden;"
          v-model="dataForm.docContent"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="onCreated"
        />
      </div>
    </el-form-item>
    <el-form-item>
      <el-button @click="closeWindow">取消</el-button>
      <el-button type="primary" @click="submitForm" :loading="editLoading"
        >提交</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
let _this = null // 定义一个全局能调用的this
export default {
  name: 'NoticeForm',
  components: {
    Editor,
    Toolbar
  },
  props: {
    objectId: Number // 在子组件中通过props接收编辑的对象主键
  },
  data() {
    return {
      editor: null,
      toolbarConfig: {},
      editorConfig: { MENU_CONF: {} },
      mode: 'default', // or 'simple'
      dataForm: {
        docId: null,
        docTitle: '', // 标题
        docContent: '', // 内容
        docType: null, // 公告类型
        noticeType: '1', // 通知发送对象类型:1-核验员；2-县级；3-市级
        publishName: '',
        sort: 1,
        parentId: 0
      },
      treeData: [], // 下拉树菜单数据
      treeProps: {
        label: 'docTitle',
        children: 'children'
      },
      dataRule: {
        docTitle: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        docType: [
          { required: true, message: '请选择公告类型', trigger: 'change' }
        ],
        publishName: [
          { required: true, message: '发布者不能为空', trigger: 'blur' }
        ]
      },
      editLoading: false
    }
  },
  watch: {
    // 通过watch函数监控objectId的变化，把值copy到本地的对象
    objectId: {
      immediate: true, // 很重要！！！ 开启首次赋值监听
      deep: true,
      handler(val) {
        if (val == null) {
          this.resetForm()
          return
        }
        this.findObj(val)
      }
    }
  },
  methods: {
    // 表单提交
    submitForm: function() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.editLoading = true
          let newDocContent = this.formatEditorText(this.dataForm.docContent)
          this.dataForm.docContent = newDocContent
          this.$api.biogas.saveDoc(this.dataForm).then(res => {
            this.editLoading = false
            if (res.code == 200) {
              this.$message.success('操作指南提交成功！')
              this.$emit('reload') //列表信息
              this.closeWindow()
            } else {
              this.$message.error('操作失败,' + res.msg)
            }
          })
        }
      })
    },

    /**
     * 处理富文本里的图片宽度自适应
     * 1.去掉img标签里的style、width、height属性
     * 2.img标签添加style属性：max-width:100%;height:auto
     * @param html
     * @returns {void|string|*}
     */
    formatEditorText(html) {
      // eslint-disable-next-line no-unused-vars
      let str = html.replace(/<img[^>]*>/gi, function(match, capture) {
        return match.replace(
          /style\s*?=\s*?([‘"])[\s\S]*?\1/gi,
          'style="max-width:100%;height:auto;"'
        ) // 替换style
      })
      // 替换视频的样式
      // eslint-disable-next-line no-unused-vars
      str = str.replace(/<video[^>]*>/gi, function(match, capture) {
        if (match.indexOf('style') === -1) {
          let styleStr = ' style="max-width:98%;height:auto;" '
          match = _this.insertStr(match, 7, styleStr)
          return match
        } else {
          return match.replace(
            /style\s*?=\s*?([‘"])[\s\S]*?\1/gi,
            'style="max-width:98%;height:auto;"'
          ) // 替换style
        }
      })
      return str
    },
    /**
     * 根据对象ID查询对象信息
     * @param id
     */
    findObj: function(id) {
      this.$api.biogas.findDocById(id).then(res => {
        if (res.code == 200) {
          this.dataForm = Object.assign({}, res.data)
          this.dataForm.docType = res.data.docType + ''
          this.dataForm.noticeType = res.data.noticeType + ''
        } else {
          this.$message.error('查询失败,' + res.msg)
        }
      })
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    createEditorConfig() {
      this.editorConfig.placeholder = '亲，请输入内容...'
      // 自定义上传图片
      this.editorConfig.MENU_CONF['uploadImage'] = {
        // 自定义上传图片,调用自己上传方法上传内容图片信息
        customUpload(file, insertFn) {
          let form = new FormData()
          form.append('file', file)
          _this.$api.biogas.uploadEditorImg(form).then(res => {
            if (res.code == 200) {
              insertFn(res.data.filePath, res.data.fileName, res.data.filePath)
            } else {
              _this.$message.error(res.msg)
            }
          })
        }
      }
      // 自定义上传视频
      this.editorConfig.MENU_CONF['uploadVideo'] = {
        // 自定义上传图片,调用自己上传方法上传内容视频信息
        async customUpload(file, insertFn) {
          let form = new FormData()
          form.append('file', file)
          _this.$api.biogas.uploadEditorVideo(form).then(res => {
            if (res.code == 200) {
              insertFn(res.data.filePath, res.data.fileName, res.data.filePath)
            } else {
              _this.$message.error(res.msg)
            }
          })
        }
      }
    },
    // 菜单树选中
    handleTreeSelectChange(data) {
      this.dataForm.parentId = data.docId
      this.dataForm.parentName = data.docTitle
    },
    // 关闭新增修改窗口
    closeWindow: function() {
      this.resetForm()
      this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
    },
    /*
	   str：原字符串
	   index：指定插入的索引文字
	    insertStr：要插入的字符串
    */
    insertStr(str, index, insertStr) {
      const ary = str.split('') // 转化为数组
      ary.splice(index, 0, insertStr) // 使用数组方法插入字符串
      return ary.join('') // 拼接成字符串后输出
    },
    // 重置表单信息
    resetForm: function() {
      this.objectId = null
      this.dataForm = {
        docId: null,
        docTitle: '', // 标题
        docContent: '', // 内容
        docType: null, // 公告类型
        noticeType: '1', // 通知发送对象类型:1-核验员；2-县级；3-市级
        publishName: '',
        sort: 1,
        parentId: 0
      }
    }
  },
  created() {
    _this = this
    this.createEditorConfig()
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped></style>
