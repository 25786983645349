<template>
  <el-form
    :model="form"
    :rules="dataRule"
    ref="dataForm"
    label-width="130px"
    size="small"
    style="text-align:left;"
  >
    <el-row>
      <el-col :span="12">
        <el-form-item label="户主姓名" prop="projectName">
          <el-input
            v-model="form.projectName"
            placeholder="请输入户主姓名"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="建设项目来源" prop="projectType">
          <el-select
            v-model="form.projectType"
            style="width: 100%;"
            placeholder="请选择建设项目来源"
          >
            <el-option
              v-for="item in typeList"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="所在区域" prop="orgId">
          <el-select
            v-model="cityValue"
            placeholder="请选择市"
            @change="chooseCity"
            style="width: 150px;"
          >
            <el-option
              v-for="item in cityList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="countyValue"
            placeholder="请选择区/县"
            @change="chooseCounty"
          >
            <el-option
              v-for="item in countyList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="streetValue"
            placeholder="请选择乡/镇"
            @change="chooseStreet"
          >
            <el-option
              v-for="item in streetList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="villageValue"
            placeholder="请选择村"
            @change="chooseVillage"
            style="width: 190px;"
          >
            <el-option
              v-for="item in villageList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="屯/队名" prop="tun">
          <el-input v-model="form.tun" placeholder="屯/队名"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="户主电话" prop="phone">
          <el-input
            v-model="form.phone"
            placeholder="请输入户主电话"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="户主身份证" prop="idCard">
          <el-input
            v-model="form.idCard"
            placeholder="请输入户主身份证"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="项目规模（m³）" prop="projectSize">
          <el-input
            v-model="form.projectSize"
            placeholder="请输入项目规模"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="建池时间" prop="buildDate">
          <el-date-picker
            v-model="form.buildDate"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="date"
            placeholder="选择建池日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="建池技工" prop="builder">
          <el-input
            v-model="form.builder"
            placeholder="请输入建池技工"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="建池编号" prop="biogasNo">
          <el-input
            v-model="form.biogasNo"
            placeholder="请输入建池编号"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="备注说明" prop="remark">
          <el-input
            type="textarea"
            :rows="2"
            v-model="form.remark"
            placeholder="请输入备注说明"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item>
      <el-button @click="closeWindow">取消</el-button>
      <el-button type=".css" @click="submitForm" :loading="editLoading"
        >提交</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'dataForm',
  props: {
    objectId: Number // 在子组件中通过props接收编辑的对象主键
  },
  data() {
    return {
      operation: true, // true:新增, false:编辑
      cityList: [], // 市列表
      countyList: [], // 区/县列表
      streetList: [], // 乡/镇列表
      villageList: [], // 村列表
      cityValue: '', // 选中的市
      countyValue: '', // 选中的区县
      streetValue: '', // 选中的乡/镇
      villageValue: '', // 选中的村
      typeList: [], // 项目建设来源
      orgOptions: [], // 机构待选项
      selectedOrgOptions: [], // 选中的部门信息
      orgProps: {
        label: 'orgName',
        value: 'orgId',
        checkStrictly: true
      },
      form: {},
      dataRule: {
        projectName: [
          { required: true, message: '户主姓名不能为空', trigger: 'blur' }
        ],
        projectType: [
          { required: true, message: '建设项目来源不能为空', trigger: 'blur' }
        ],
        orgId: [
          { required: true, message: '所属区域不能为空', trigger: 'blur' }
        ],
        projectSize: [
          { required: true, message: '项目规模不能为空', trigger: 'blur' }
        ],
        buildDate: [
          { required: true, message: '建池时间不能为空', trigger: 'blur' }
        ]
      },
      editLoading: false
    }
  },
  watch: {
    // 通过watch函数监控objectId的变化，把值copy到本地的对象
    objectId: {
      immediate: true, // 很重要！！！ 开启首次赋值监听
      deep: true,
      handler(val) {
        if (val == null || val === 0) {
          this.resetForm()
          return
        }
        this.findObj(val)
      }
    }
  },
  methods: {
    initLoad() {
      // 加载机构下拉框
      this.$api.org.getOrgByPid(1).then(resp => {
        if (resp.code === 200) {
          this.cityList = resp.data
        } else {
          this.$message.error(resp.msg)
        }
      })
      // 加载项目建设来源
      this.$api.dict.findDictByType('household_project_type').then(res => {
        if (res.code === 200) {
          this.typeList = res.data
        }
      })
    },
    // 表单提交
    submitForm: function() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.editLoading = true
          this.form.biogasType = 1 // 户用沼气池
          this.$api.biogas.saveProject(this.form).then(res => {
            this.editLoading = false
            if (res.code == 200) {
              this.$message.success('操作成功！')
              this.$emit('reload') //列表信息
              this.closeWindow()
            } else {
              this.$message.error('操作失败,' + res.msg)
            }
          })
        }
      })
    },
    findObj: function(id) {
      this.operation = false
      this.$api.biogas.findProjectById(id).then(res => {
        if (res.code == 200) {
          this.form = Object.assign({}, res.data)
          this.form.projectType = this.form.projectType + ''
          this.initSelectOrg(this.form.orgPath)
        } else {
          this.$message.error('查询失败,' + res.msg)
        }
      })
    },
    // 初始化区域选中信息
    initSelectOrg(orgPath) {
      // 分割路径ID
      let orgIds = orgPath.split(',')
      // 因为区域选择框是市级开始，而且路径等是从 顶级->省级->市级->区级->乡级->村级
      if (orgIds.length == 3) {
        // 只到市级
        this.cityValue = orgIds[2]
        this.chooseCity(this.cityValue)
      }
      if (orgIds.length == 4) {
        // 只到区级
        this.cityValue = orgIds[2]
        this.chooseCity(this.cityValue)
        this.countyValue = orgIds[3]
        this.chooseCounty(this.countyValue)
      }
      if (orgIds.length == 5) {
        // 只到乡级
        this.cityValue = orgIds[2]
        this.chooseCity(this.cityValue)
        this.countyValue = orgIds[3]
        this.chooseCounty(this.countyValue)
        this.streetValue = orgIds[4]
        this.chooseStreet(this.streetValue)
      }
      if (orgIds.length == 6) {
        // 只到乡级
        this.cityValue = parseInt(orgIds[2])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[3])
        this.chooseCounty(this.countyValue)
        this.streetValue = parseInt(orgIds[4])
        this.chooseStreet(this.streetValue)
        this.villageValue = parseInt(orgIds[5])
        this.chooseVillage(this.villageValue)
      }
    },
    // 关闭新增修改窗口
    closeWindow: function() {
      this.resetForm()
      this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
    },
    // 重置表单信息
    resetForm: function() {
      this.form = {}
      this.cityValue = ''
      this.countyList = []
      this.countyValue = ''
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
    },
    /**选中市**/
    chooseCity: function(value) {
      this.countyList = []
      this.countyValue = ''
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
      this.form.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.countyList = resp.data
        }
      })
    },
    /**选中区县**/
    chooseCounty: function(value) {
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
      this.form.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.streetList = resp.data
        }
      })
    },
    /**选中乡/镇**/
    chooseStreet: function(value) {
      this.villageList = []
      this.villageValue = ''
      this.form.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.villageList = resp.data
        }
      })
    },
    /**选中村**/
    chooseVillage: function(value) {
      this.form.orgId = value
    }
  },
  mounted() {
    this.initLoad()
  }
}
</script>

<style scoped></style>
