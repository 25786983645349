import fileDownload from "js-file-download"
<template>
  <div class="page-container">
    <!--工具栏-->
    <div class="toolbar" style="float:left;padding-top:10px;padding-left:15px;">
      <el-form
        :inline="true"
        :model="filters"
        :size="size"
        style="text-align:left;"
      >
        <el-form-item>
          <el-input
            v-model="filters.projectName"
            placeholder="项目名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="filters.verStatus"
            style="width: 100px"
            clearable
            placeholder="核验状态"
          >
            <el-option
              v-for="item in verStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="cityValue"
            placeholder="请选择市"
            @change="chooseCity"
            style="width: 150px;"
          >
            <el-option
              v-for="item in cityList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="countyValue"
            placeholder="请选择区/县"
            @change="chooseCounty"
          >
            <el-option
              v-for="item in countyList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="streetValue"
            placeholder="请选择乡/镇"
            @change="chooseStreet"
            style="width: 150px;"
          >
            <el-option
              v-for="item in streetList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="villageValue"
            placeholder="请选择村"
            @change="chooseVillage"
            style="width: 150px;"
          >
            <el-option
              v-for="item in villageList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="filters.dataSource"
            style="width: 100px"
            clearable
            placeholder="数据来源"
          >
            <el-option
              v-for="item in dataSourceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-search"
            label="查询"
            type="primary"
            @click="findPage(null)"
          />
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-file-excel-o"
            label="EXCEL导入"
            type="warning"
            perms="biogas:project:import"
            @click="handleImport"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--表格内容栏-->
    <base-table
      :data="pageResult"
      :columns="columns"
      :maxHeight="tableHeight"
      :showOperation="showOperation"
      @findPage="findPage"
    >
      <!--状态格式化 slot="formatStatus" 要与下列的表格prop 设置的值一致-->
      <template slot="formatVerStatus" slot-scope="scope">
        <el-tag :style="scope.row.verStatus | statusFilter(2)">{{
          scope.row.verStatus | statusFilter(1)
        }}</el-tag>
      </template>
      <template slot="formatDataSource" slot-scope="scope">
        <el-tag :type="scope.row.dataSource | sourceFilter(2)">{{
          scope.row.dataSource | sourceFilter(1)
        }}</el-tag>
      </template>
      <template slot="optSolt" slot-scope="scope">
        <base-button
          icon="fa fa-search"
          label="查看"
          size="mini"
          type="primary"
          @click="handleView(scope.row.projectId)"
        />
        <base-button
          v-if="scope.row.lastHisBatchId < scope.row.batchId"
          icon="fa fa-share-alt"
          label="核验历史"
          size="mini"
          type="success"
          @click="handleHisView(scope.row.projectId)"
        />
      </template>
    </base-table>
    <!-- 上传导入EXCEL文件 -->
    <el-dialog
      title="沼气工程EXCEL上传导入"
      width="40%"
      :visible.sync="importVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <project-import
        :importType="importType"
        @close="importVisible = false"
        @reload="findPage(null)"
      />
    </el-dialog>
    <!-- 核验项目审核界面 -->
    <el-dialog
      :title="!objectId ? '新增' : '修改'"
      width="70%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <site-form
        :objectId="objectId"
        @close="dialogVisible = false"
        @reload="findPage(null)"
      />
    </el-dialog>
    <!-- 沼气工程详细信息界面 -->
    <el-dialog
      title="沼气工程详细信息"
      width="70%"
      :visible.sync="dialogViewVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <site-view :objectId="objectId" @close="dialogViewVisible = false" />
    </el-dialog>
    <el-dialog
      title="沼气工程历史核验信息"
      width="70%"
      :visible.sync="dialogHisViewVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <site-his-view
        :objectId="objectId"
        @close="dialogHisViewVisible = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import fileDownload from 'js-file-download'
import BaseTable from '@/components/BaseTable' // 树型列表
import BaseButton from '@/components/BaseButton' // 公共按钮
import ProjectImport from './ProjectImport' // 沼气池项目数据导入页面
import SiteForm from './SiteForm' // 沼气池项目添加
import SiteView from './SiteView'
import SiteHisView from './SiteHisView'
let _this = null // 定义一个全局能调用的this
export default {
  name: 'site',
  components: {
    BaseTable,
    BaseButton,
    ProjectImport,
    SiteForm,
    SiteView,
    SiteHisView
  },
  data() {
    return {
      size: 'small', // 样式图标大小
      showOperation: false, // 不显示模板的操作状态栏
      tableHeight: window.innerHeight - 220, // 表格的高度
      cityList: [], // 市列表
      countyList: [], // 区/县列表
      streetList: [], // 乡/镇列表
      villageList: [], // 村列表
      cityValue: '', // 选中的市
      countyValue: '', // 选中的区县
      streetValue: '', // 选中的乡/镇
      villageValue: '', // 选中的村
      importType: null, // 上传项目类型：1-户用沼气池 2-沼气工程
      importVisible: false, // 上传导入弹出框
      typeList: [], // 项目建设来源
      verStatusOptions: [
        { value: -1, label: '审核退回', color: '#cd6363' },
        { value: 1, label: '未核验', color: '#2628ab' },
        { value: 2, label: '审核中', color: '#22cdc2' },
        { value: 3, label: '已归档', color: '#06ad0d' },
        { value: 4, label: '待上传报废材料', color: '#ad7a1d' }
      ], // 核验状态
      dataSourceOptions: [
        { value: 1, label: '档案数据', color: 'success' },
        { value: 2, label: '核验新增', color: 'warning' }
      ], // 数据来源
      filters: {
        projectName: '',
        verStatus: null,
        dataSource: null,
        orgId: null
      },
      columns: [], // 初始表头信息
      pageRequest: { pageNum: 1, pageSize: 10 }, // 分页请示参数信息, 当前页,每页大小
      pageResult: {}, // 分页查询结果集
      dialogVisible: false, // 新增和修改页面窗口打开标识
      objectId: null, // 对象主键ID,用于修改时进行传递
      dialogViewVisible: false, //沼气信息详细页面
      dialogHisViewVisible: false //查看历史核验页面窗口打开标识
    }
  },
  methods: {
    initLoad() {
      // 加载项目建设来源
      this.$api.dict.findDictByType('site_project_type').then(res => {
        if (res.code === 200) {
          this.typeList = res.data
        }
      })
      // 加载机构下拉框
      this.$api.org.getOrgByPid(1).then(resp => {
        if (resp.code === 200) {
          this.cityList = resp.data
        } else {
          this.$message.error(resp.msg)
        }
      })
    },
    /**
     * 分页查询数据
     * @param pageParams 分页请求参数
     */
    findPage: function(pageParams) {
      this.objectId = null
      if (pageParams !== null) {
        this.pageRequest = pageParams.pageRequest
      }
      this.pageRequest.Q_projectName_S_LK = this.filters.projectName
      this.pageRequest.Q_verStatus_N_EQ = this.filters.verStatus
      this.pageRequest.Q_dataSource_N_EQ = this.filters.dataSource
      this.pageRequest.orgId = this.filters.orgId
      this.pageRequest.Q_biogasType_N_EQ = 2
      this.$api.biogas
        .findPage(this.pageRequest)
        .then(res => {
          if (res.code === 200) {
            this.pageResult = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .then(pageParams != null ? pageParams.callback : '')
    },
    /***
     * 初始化列表显示
     */
    initColumns: function() {
      this.columns = [
        {
          prop: 'projectName',
          label: '项目名称',
          sortable: false,
          minWidth: 140
        },
        { prop: 'address', label: '详细地址', sortable: false, minWidth: 140 },
        {
          prop: 'projectType',
          label: '项目类型',
          sortable: false,
          minWidth: 100,
          formatter: this.formatType
        },
        { prop: 'contact', label: '业主姓名', sortable: false, minWidth: 90 },
        { prop: 'phone', label: '业主电话', sortable: false, minWidth: 90 },
        {
          prop: 'projectSize',
          label: '项目规模（m³）',
          sortable: false,
          minWidth: 110
        },
        {
          prop: 'formatDataSource',
          label: '数据来源',
          sortable: false,
          slot: true
        },
        {
          prop: 'buildDate',
          label: '建池时间',
          sortable: false,
          minWidth: 100
        },
        {
          prop: 'formatVerStatus',
          label: '核验状态',
          sortable: false,
          slot: true
        },
        {
          prop: 'batchId',
          label: '当前核验年度',
          sortable: false,
          minWidth: 100
        },
        {
          prop: 'verTime',
          label: '核验时间',
          sortable: false,
          minWidth: 140
        },
        {
          prop: 'createTime',
          label: '创建时间',
          sortable: false,
          minWidth: 140
        },
        {
          prop: 'optSolt',
          label: '操作',
          sortable: false,
          slot: true,
          minWidth: 200
        }
      ]
    },
    handleAdd: function() {
      this.dialogVisible = true
      this.objectId = null
    },
    // 显示编辑界面
    handleEdit: function(row) {
      this.dialogVisible = true
      this.objectId = row.projectId
    },
    // 设置可视区域高度
    initHeight: function() {
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          _this.tableHeight = window.innerHeight - 220
        })()
      }
    },
    formatType(row) {
      let typeStr = ''
      this.typeList.forEach(item => {
        if (item.dictValue == String(row.projectType)) {
          typeStr = item.dictLabel
        }
      })
      return typeStr
    },
    // 打印导入窗口
    handleImport() {
      this.importType = 2
      this.importVisible = true
    },
    //显示沼气池详细页面
    handleView(projectId) {
      this.objectId = projectId
      this.dialogViewVisible = true
    },
    //显示历史核验页面
    handleHisView(projectId) {
      this.objectId = projectId
      this.dialogHisViewVisible = true
    },
    //下载数据模板
    handleDownload() {
      this.$api.biogas.downLoadTemplate(2).then(res => {
        fileDownload(res, '沼气工程基本信息统计表.xlsx')
      })
    },
    /**选中市**/
    chooseCity: function(value) {
      this.countyList = []
      this.countyValue = ''
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
      this.filters.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.countyList = resp.data
        }
      })
    },
    /**选中区县**/
    chooseCounty: function(value) {
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
      this.filters.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.streetList = resp.data
        }
      })
    },
    /**选中乡/镇**/
    chooseStreet: function(value) {
      this.villageList = []
      this.villageValue = ''
      this.filters.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.villageList = resp.data
        }
      })
    },
    /**选中村**/
    chooseVillage: function(value) {
      this.filters.orgId = value
    }
  },
  mounted() {
    _this = this
    this.initHeight()
    this.initLoad()
    this.initColumns()
  },
  filters: {
    /**
     * 状态格式化
     * @param status 当前显示状态
     * @param type 1 获取内容，2 获得颜色
     * @returns {string|*} 格式化后结果
     */
    statusFilter(status, type) {
      for (let i = 0; i < _this.verStatusOptions.length; i++) {
        if (status == _this.verStatusOptions[i].value) {
          if (1 == type) {
            return _this.verStatusOptions[i].label
          } else {
            return 'color: ' + _this.verStatusOptions[i].color + ';'
          }
        }
      }
    },
    sourceFilter(status, type) {
      for (let i = 0; i < _this.dataSourceOptions.length; i++) {
        if (status == _this.dataSourceOptions[i].value) {
          if (1 == type) {
            return _this.dataSourceOptions[i].label
          } else {
            return _this.dataSourceOptions[i].color
          }
        }
      }
    }
  }
}
</script>

<style>
/* 顶部下划线 */
.dialog-mask .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
.dialog-mask .el-dialog__body {
  padding: 10px 20px;
}
</style>
