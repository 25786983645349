<template>
  <div v-loading="loading" ref="reportDiv" :style="'height:' + reportDivHeight">
    <iframe
      :src="src"
      frameborder="no"
      style="width: 100%;height: 100%"
      scrolling="auto"
    />
  </div>
</template>

<script>
export default {
  name: 'ureport',
  data() {
    return {
      src: '/ureport/designer',
      reportDivHeight: document.documentElement.clientHeight - 110 + 'px;',
      loading: true
    }
  },
  mounted() {
    this.resetDivHeight()
  },
  methods: {
    resetDivHeight() {
      const _this = this
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          let clientHeight = document.documentElement.clientHeight
          _this.reportDivHeight = clientHeight - 110
        })()
      }
      setTimeout(() => {
        _this.loading = false
      }, 1000)
    }
  },
  watch: {
    reportDivHeight: {
      // 监听监测时间发生变化时，进行调用查询
      deep: true,
      handler() {
        this.$refs.reportDiv.style.height = this.reportDivHeight + 'px'
      }
    }
  }
}
</script>

<style scoped></style>
