<template>
  <div>
    <!--查询工具栏-->
    <div style="float:left;padding-left:5px;">
      <el-form :inline="true" size="small">
        <el-form-item>
          <el-select
            v-model="cityValue"
            placeholder="请选择市"
            @change="chooseCity"
            style="width: 130px;"
            :disabled="cityDisabled"
          >
            <el-option
              v-for="item in cityList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="countyValue"
            placeholder="请选择区/县"
            @change="chooseCounty"
            :disabled="countyDisabled"
            style="width: 130px;"
          >
            <el-option
              v-for="item in countyList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="streetValue"
            placeholder="请选择乡/镇"
            @change="chooseStreet"
            style="width: 150px;"
            :disabled="streetDisabled"
          >
            <el-option
              v-for="item in streetList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="villageValue"
            placeholder="请选择村"
            clearable
            style="width: 150px;"
          >
            <el-option
              v-for="item in villageList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="tun"
            placeholder="屯名"
            clearable
            style="width: 130px;"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="useStatus"
            placeholder="请选择核验情况"
            clearable
            style="width: 150px;"
          >
            <el-option
              v-for="item in useOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="verName"
            placeholder="核验员姓名"
            clearable
            style="width: 130px;"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="biogasType"
            placeholder="请选择沼气类型"
            style="width: 130px;"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-search"
            label="查询"
            type="primary"
            @click="search"
          />
        </el-form-item>
      </el-form>
    </div>
    <div
      style="float:left;padding-left:200px;text-align:center"
      v-if="showVisible"
    >
      <span style="color: red; "
        >亲，请选择查询条件后，再点击“查询”按钮进行查询，可点击报表左上角“EXCEL图标”导出查询清单，谢谢！</span
      >
    </div>
    <div
      v-loading="loading"
      ref="reportDiv"
      :style="'height:' + reportDivHeight"
    >
      <iframe
        :src="src"
        frameborder="no"
        style="width: 100%;height: 100%"
        scrolling="auto"
      />
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton'
import Cookies from 'js-cookie'
export default {
  name: 'report-project-imp',
  components: {
    BaseButton
  },
  data() {
    return {
      src: '',
      reportDivHeight: document.documentElement.clientHeight - 165 + 'px;',
      loading: false,
      cityList: [], // 市列表
      countyList: [], // 区/县列表
      streetList: [], // 乡/镇列表
      villageList: [], // 村列表
      cityValue: '', // 选中的市
      countyValue: '', // 选中的区县
      streetValue: '', // 选中的乡/镇
      villageValue: '', // 选中的村
      tun: '', //屯名
      cityDisabled: false, //市下拉框禁止选择
      countyDisabled: false, //市下拉框禁止选择
      streetDisabled: false, //市下拉框禁止选择
      showVisible: true,
      typeOptions: [
        {
          value: 1,
          label: '户用沼气池'
        },
        {
          value: 2,
          label: '沼气工程'
        }
      ],
      verOptions: [
        {
          value: 1,
          label: '未核验'
        },
        {
          value: 2,
          label: '已核验未审核'
        },
        {
          value: 3,
          label: '已审核归档'
        }
      ],
      useOptions: [
        { value: -1, label: '待查项目' },
        { value: 1, label: '正常(间歇)使用' },
        { value: 2, label: '停用' },
        { value: 3, label: '报废' }
      ], // 使用情况
      verStatus: 1, // 核验状态默认未核验
      useStatus: '', //核验情况
      verName: '', //核验人姓名
      biogasType: 1 // 沼气类型
    }
  },
  mounted() {
    this.initLoad()
    this.initSelectOrg()
  },
  methods: {
    initLoad() {
      // 加载机构下拉框
      this.$api.org.getOrgByPid(1).then(resp => {
        if (resp.code === 200) {
          this.cityList = resp.data
        } else {
          this.$message.error(resp.msg)
        }
      })
    },
    search() {
      if (this.streetValue == '') {
        this.$message.error(
          '亲，由于数据量比较大，为了性能考虑，请先选择到乡镇或村条件后，再查询并导出清单，谢谢！'
        )
        return
      }
      this.loading = true
      let uri =
        '/ureport/preview?_u=report-project_his_ver_list.ureport.xml&_t=3,4,6,9&_i=1'
      uri = uri + '&cityId=' + this.cityValue
      uri = uri + '&areaId=' + this.countyValue
      uri = uri + '&streetId=' + this.streetValue
      uri = uri + '&villageId=' + this.villageValue
      uri = uri + '&biogasType=' + this.biogasType
      uri = uri + '&tun=' + this.tun
      uri = uri + '&verName=' + this.verName
      uri = uri + '&useStatus=' + this.useStatus
      this.src = uri
      this.showVisible = false
      this.resetDivHeight()
    },
    /**选中市**/
    chooseCity(value) {
      this.countyList = []
      this.countyValue = ''
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.countyList = resp.data
          //设置默认值
          if (this.countyValue === '') {
            this.countyValue = this.countyList[0].orgId
            this.chooseCounty(this.countyValue)
          }
        }
      })
    },
    /**选中区县**/
    chooseCounty(value) {
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.streetList = resp.data
          //设置默认值
          if (this.streetValue === '') {
            this.streetValue = this.streetList[0].orgId
            this.chooseStreet(this.streetValue)
          }
        }
      })
    },
    /**选中乡/镇**/
    chooseStreet(value) {
      this.villageList = []
      this.villageValue = ''
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.villageList = resp.data
        }
      })
    },
    // 初始化区域选中信息
    initSelectOrg() {
      // 获取当前登录用户的机构信息
      // 因为区域选择框是市级开始，而且路径等是从 省级->市级->区级->乡级->村级
      let orgPath = Cookies.get('user_orgIds')
      // 分割路径ID
      let orgIds = orgPath.split(',')
      if (orgIds.length === 1) {
        // 当为省级时，默认显示南宁地区
        this.cityValue = 2
        this.chooseCity(this.cityValue)
      }
      if (orgIds.length === 2) {
        // 当前账号到市级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.cityDisabled = true
      }
      // 当前账号到县级
      if (orgIds.length === 3) {
        // 只到区级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[2])
        this.chooseCounty(this.countyValue)
        this.cityDisabled = true
        this.countyDisabled = true
      }
      // 当前账号的机构到乡镇级
      if (orgIds.length === 4) {
        // 只到乡级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[2])
        this.chooseCounty(this.countyValue)
        this.streetValue = parseInt(orgIds[3])
        this.chooseStreet(this.streetValue)
        this.cityDisabled = true
        this.countyDisabled = true
        this.streetDisabled = true
      }
      if (orgIds.length === 5) {
        // 村级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[2])
        this.chooseCounty(this.countyValue)
        this.streetValue = parseInt(orgIds[3])
        this.chooseStreet(this.streetValue)
        this.villageValue = parseInt(orgIds[4])
        this.chooseVillage(this.villageValue)
        this.cityDisabled = true
        this.countyDisabled = true
        this.streetDisabled = true
      }
    },
    resetDivHeight() {
      const _this = this
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          let clientHeight = document.documentElement.clientHeight
          _this.reportDivHeight = clientHeight - 165
        })()
      }
      setTimeout(() => {
        _this.loading = false
      }, 1000)
    }
  },
  watch: {
    reportDivHeight: {
      // 监听监测时间发生变化时，进行调用查询
      deep: true,
      handler() {
        this.$refs.reportDiv.style.height = this.reportDivHeight + 'px'
      }
    }
  }
}
</script>

<style scoped></style>
