<template>
  <div>
    <el-upload
      drag
      ref="uploadFiles"
      :auto-upload="false"
      accept=".xlsx,.jpg,.png"
      action="#"
      :before-upload="beforeUploadFile"
      :on-change="fileChange"
      :on-exceed="exceedFile"
      :file-list="fileList"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">
        只能上传xlsx或图片文件，且不超过10M
      </div>
    </el-upload>
    <br />
    <el-button
      size="small"
      type="primary"
      :loading="loginLoading"
      @click="uploadFile"
      >立即上传</el-button
    >
  </div>
</template>

<script>
export default {
  name: 'fiileImport',
  props: {
    importType: Number, //上传项目类型：1-excel数据导入 2-户用表图片上传
    meterId: Number // 当上传图片时，该参数必须有值
  },
  data() {
    return {
      loginLoading: false,
      fileList: [] // excel文件列表
    }
  },
  methods: {
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      if (this.importType === 1 && this.fileList.length > 1) {
        this.$message.warning(
          `亲，户用表上传时只能选择 1 个文件，当前共选择了 ${files.length +
            fileList.length} 个`
        )
      }
    },
    // fileList 是文件列表发生变化后，返回的修改后的列表对象，这里直接赋值就好啦！
    // eslint-disable-next-line no-unused-vars
    fileChange(file, fileList) {
      this.fileList = fileList
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUploadFile(file) {
      // 获取文件后缀名
      let extension = file.name
        .substring(file.name.lastIndexOf('.') + 1)
        .toLocaleLowerCase()
      if (this.importType === 1 && extension !== 'xlsx') {
        this.$message.warning('只能上传后缀是.xlsx的文件')
      }
      const isAcceptFiles = extension === 'png' || extension === 'jpg'
      if (this.importType === 2 && !isAcceptFiles) {
        this.$message.warning('只能上传后缀是.png,.jpg的文件')
      }
      let size = file.size / 1024 / 1024
      if (size > 10) {
        this.$message.warning('文件大小不得超过10M')
      }
    },
    uploadFile() {
      if (this.fileList.length === 0) {
        this.$message.warning('请上传文件')
      } else {
        this.loginLoading = true
        let form = new FormData()
        if (this.importType == 1) {
          form.append('file', this.fileList[0].raw)
          this.$api.gas.importMeterExcel(form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.$emit('reload') //列表信息
              this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
            } else {
              this.$message.error(res.msg)
            }
            this.loginLoading = false
            this.fileList = []
            this.$refs.uploadFiles.clearFiles()
            this.loginLoading = false
          })
        }
        if (this.importType == 2) {
          this.fileList.forEach(file => {
            form.append('files', file.raw)
          })
          form.append('meterId', this.meterId)
          this.$api.gas.importMeterImg(form).then(res => {
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
            } else {
              this.$message.error(res.msg)
            }
            this.fileList = []
            this.$refs.uploadFiles.clearFiles()
            this.loginLoading = false
          })
        }
      }
    }
  }
}
</script>

<style scoped></style>
