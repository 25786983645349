<template>
  <div class="detail__wrap">
    <el-collapse-transition>
      <div class="detail__content">
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                仪器编号
              </div>
              <div class="content__item--message">
                {{ form.meterNo }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                户主姓名
              </div>
              <div class="content__item--message">
                {{ form.contact }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                联系电话
              </div>
              <div class="content__item--message">
                {{ form.phone }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                身份证号
              </div>
              <div class="content__item--message">
                {{ form.idCard }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                地址
              </div>
              <div class="content__item--message">
                {{ form.address }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                建池时间
              </div>
              <div class="content__item--message">
                {{ form.buildDate }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                累计流量（m3）
              </div>
              <div class="content__item--message">
                {{ form.sumFlow }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                最新一次采集时间
              </div>
              <div class="content__item--message">
                {{ form.lastRecordDate }}
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                状态
              </div>
              <div class="content__item--message">
                {{ form.status == 1 ? '正常' : '无信号' }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <div
              class="detail__content--item"
              :class="{ 'detail__content--flex': !vertical }"
            >
              <div class="content__item--label">
                照片
              </div>
              <div class="content__item--message" style="text-align: left;">
                <el-upload
                  action="#"
                  class="disUoloadSty"
                  list-type="picture-card"
                  :limit="5"
                  :auto-upload="false"
                  :file-list="smallImgList"
                >
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img
                      class="el-upload-list__item-thumbnail"
                      :src="file.smallPath"
                      alt=""
                    />
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
                <el-image-viewer
                  v-if="showViewer"
                  z-index="9999"
                  :on-close="closeViewer"
                  :url-list="getViewer()"
                />
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: '户用表详细信息页面',
  components: {
    ElImageViewer
  },
  props: {
    objectId: Number // 在子组件中通过props接收编辑的对象主键
  },
  data() {
    return {
      vertical: false,
      smallImgList: [], //缩略图地址
      imgList: [], // 大图地址
      showViewer: false, // 显示大图
      imgIndex: 0, // 点击查看哪张图片
      form: {} // 页面显示表单信息
    }
  },
  watch: {
    // 通过watch函数监控objectId的变化，把值copy到本地的对象
    objectId: {
      immediate: true, // 很重要！！！ 开启首次赋值监听
      deep: true,
      handler(val) {
        if (val == null) {
          return
        }
        this.findObj(val)
      }
    }
  },
  methods: {
    /**
     * 根据ID查询对象信息
     * @param id
     */
    findObj: function(id) {
      this.smallImgList = []
      this.imgList = []
      this.$api.gas.findMeterInfo(id).then(res => {
        if (res.code == 200) {
          this.form = Object.assign({}, res.data)
          // 拼接得到图片信息对象
          let imgs = this.form.imgs
          for (let i = 0; i < imgs.length; i++) {
            let obj = {
              id: i,
              smallPath: imgs[i].smallPath
            }
            this.smallImgList.push(obj)
            // 将图片信息对象，push到imgList数组中
            this.imgList.push(imgs[i].imgPath)
          }
        } else {
          this.$message.error('查询失败,' + res.msg)
        }
      })
    },
    // 显示图片大图
    handlePictureCardPreview(file) {
      this.imgIndex = file.id
      this.showViewer = true
    },
    // 关闭预览大图
    closeViewer() {
      this.showViewer = false
    },
    getViewer() {
      let tempImgList = [...this.imgList] //所有图片地址
      if (this.imgIndex === 0) {
        return tempImgList
      }
      // 调整图片顺序，把当前图片放在第一位
      let start = tempImgList.splice(this.imgIndex)
      let remain = tempImgList.splice(0, this.imgIndex)
      return start.concat(remain) //将当前图片调整成点击缩略图的那张图片
    },
    // 关闭新增修改窗口
    closeWindow: function() {
      this.$emit('reload') //列表信息
      this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
    }
  }
}
</script>

<style lang="less">
.detail__wrap {
  background: #fff;
  border-radius: 2px;
  .detail__wrap--header {
    display: flex;
    height: 32px;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .detail__wrap--title {
      display: inline-block;
      font-size: 18px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      margin-left: 10px;
      &:after {
        content: '';
        width: 3px;
        height: 100%;
        background: #2d8cf0;
        border-radius: 2px;
        position: absolute;
        top: 1px;
        left: -10px;
      }
    }
  }
  .detail__content {
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    .detail__content--flex {
      display: flex;
      height: 100%;
    }
    .content__item--label {
      font-size: 14px;
      padding: 8px 16px;
      width: 150px;
      text-align: left;
      background-color: #fafafa;
      border-right: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
    .content__item--message {
      flex: 1;
      font-size: 14px;
      padding: 8px 16px;
      line-height: 20px;
      color: #606266;
      width: 100%;
      border-right: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
