<template>
  <div class="page-container">
    <!--工具栏-->
    <div class="toolbar" style="float:left;padding-top:10px;padding-left:15px;">
      <el-form
        :inline="true"
        :model="filters"
        :size="size"
        style="text-align:left;"
      >
        <el-form-item>
          <el-input
            v-model="filters.userName"
            placeholder="用户名(手机号码)"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-search"
            label="查询"
            type="primary"
            @click="findPage(null)"
          />
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-share-square-o"
            label="批量退回"
            type="success"
            @click="handleBatchApprove"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--表格内容栏-->
    <base-table
      :data="pageResult"
      :columns="columns"
      :maxHeight="tableHeight"
      :showOperation="showOperation"
      :showSelection="showSelection"
      @selectionChange="selectionChange"
      @findPage="findPage"
    >
    </base-table>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable' // 树型列表
import BaseButton from '@/components/BaseButton' // 公共按钮
let _this = null // 定义一个全局能调用的this
export default {
  name: 'household',
  components: {
    BaseTable,
    BaseButton
  },
  data() {
    return {
      size: 'small', // 样式图标大小
      showOperation: false, // 不显示模板的操作状态栏
      showSelection: true,
      tableHeight: window.innerHeight - 220, // 表格的高度
      filters: {
        userName: ''
      },
      columns: [], // 初始表头信息
      pageRequest: { pageNum: 1, pageSize: 10 }, // 分页请示参数信息, 当前页,每页大小
      pageResult: {}, // 分页查询结果集
      dialogVisible: false, // 新增和修改页面窗口打开标识
      objectId: null, // 对象主键ID,用户于修改时进行传递
      checkRows: [] // 复选框选中的行
    }
  },
  methods: {
    /**
     * 分页查询数据
     * @param pageParams 分页请求参数
     */
    findPage: function(pageParams) {
      if (pageParams !== null) {
        this.pageRequest = pageParams.pageRequest
      }
      this.pageRequest.userName = this.filters.userName
      this.$api.biogas
        .findLoseImgProjectPage(this.pageRequest)
        .then(res => {
          if (res.code === 200) {
            this.pageResult = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .then(pageParams != null ? pageParams.callback : '')
    },
    /***
     * 初始化列表显示
     */
    initColumns: function() {
      this.columns = [
        { prop: 'verName', label: '核验人', sortable: false, minWidth: 100 },
        {
          prop: 'projectName',
          label: '户主姓名',
          sortable: false,
          minWidth: 100
        },
        { prop: 'address', label: '详细地址', sortable: false, minWidth: 160 },
        {
          prop: 'verTime',
          label: '核验提交时间',
          sortable: false,
          minWidth: 120
        }
      ]
    },
    handleAdd: function() {},
    // 复选框选中事件
    selectionChange(params) {
      this.checkRows = params.selections
    },
    // 设置可视区域高度
    initHeight: function() {
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          _this.tableHeight = window.innerHeight - 220
        })()
      }
    },
    //批量审核
    handleBatchApprove() {
      // 获得要审核审核id,使用逗号隔开
      let ids = this.checkRows.map(item => item.verId).toString()
      if (ids == '' || ids == null) {
        this.$message.error('亲，请选择要批量审核的核验项目信息!')
      }
      this.$confirm('确认要退回记录吗？？', '提示', {}).then(() => {
        let paramObj = {}
        paramObj.verIds = ids
        paramObj.approveOpn = '麻烦重新拍照提交核验，其他核验信息不需要修改。'
        this.$api.biogas.batchBackProject(paramObj).then(res => {
          if (res.code == 200) {
            this.$message.success('批量退回成功！')
            this.findPage(null)
          } else {
            this.$message.error('批量审核失败,' + res.msg)
          }
        })
      })
    }
  },
  mounted() {
    _this = this
    this.initHeight()
    this.initColumns()
  }
}
</script>

<style>
/* 顶部下划线 */
.dialog-mask .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
.dialog-mask .el-dialog__body {
  padding: 10px 20px;
}
</style>
