<template>
  <div class="page-container">
    <!--工具栏-->
    <div class="toolbar" style="float:left;padding-top:10px;padding-left:15px;">
      <el-form :inline="true" :model="filters" :size="size">
        <el-form-item>
          <el-input
            v-model="filters.roleName"
            placeholder="角色名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-search"
            label="查询"
            type="primary"
            @click="findPage(null)"
          />
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-plus"
            label="新增"
            type="success"
            @click="handleAdd"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--表格内容栏-->
    <base-table
      :data="pageResult"
      :columns="columns"
      :maxHeight="tableHeight"
      :showOperation="showOperation"
      @findPage="findPage"
    >
      <!--状态格式化 slot="formatStatus" 要与下列的表格prop 设置的值一致-->
      <template slot="formatStatus" slot-scope="scope">
        <el-tag :type="scope.row.status | statusFilter(2)">{{
          scope.row.status | statusFilter(1)
        }}</el-tag>
      </template>
      <template slot="optSolt" slot-scope="scope">
        <base-button
          icon="fa fa-edit"
          label="编辑"
          size="mini"
          type="primary"
          @click="handleEdit(scope.row)"
        />
        <base-button
          icon="fa fa-trash"
          label="删除"
          size="mini"
          type="danger"
          @click="handleDelete(scope.row)"
        />
        <base-button
          icon="fa fa-book"
          label="菜单权限"
          size="mini"
          type="success"
          @click="handleMenuPermission(scope.row)"
        />
        <base-button
          icon="fa fa-database"
          label="数据权限"
          size="mini"
          type="warning"
          @click="handleDataPermission(scope.row)"
        />
      </template>
    </base-table>

    <el-dialog
      title="分配菜单权限"
      :visible.sync="dialogMenuPermsVisible"
      custom-class="dialog-mask"
    >
      <el-tree
        class="filter-tree"
        :data="treeMenuData"
        :default-checked-keys="checkedKeys"
        node-key="menuId"
        check-strictly
        highlight-current
        :props="defaultProps"
        show-checkbox
        ref="menuTree"
        default-expand-all
      >
      </el-tree>
      <div slot="footer">
        <el-button type="primary" @click="submitMenuPerms(roleId)"
          >提 交</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="角色分配数据权限"
      width="60%"
      :visible.sync="dialogDataPermsVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <role-data-scope-form
        :objectId="objectId"
        @close="dialogDataPermsVisible = false"
        @reload="findPage(null)"
      />
    </el-dialog>

    <!-- 新增修改界面 -->
    <el-dialog
      :title="!objectId ? '新增' : '修改'"
      width="40%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <role-form
        :objectId="objectId"
        @close="dialogVisible = false"
        @reload="findPage(null)"
      />
    </el-dialog>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable' // 树型列表
import BaseButton from '@/components/BaseButton' // 公共按钮
import RoleForm from './RoleForm' // 新增和编辑页面
import RoleDataScopeForm from './RoleDataScopeForm' // 角色数据授权页面
export default {
  name: 'role',
  components: {
    BaseTable,
    BaseButton,
    RoleForm,
    RoleDataScopeForm
  },
  data() {
    return {
      size: 'small', // 样式图标大小
      showOperation: false, // 不显示模板的操作状态栏
      dialogMenuPermsVisible: false, // 分配菜单权限窗口显示
      dialogDataPermsVisible: false, // 分配数据权限窗口显示
      tableHeight: window.innerHeight - 220, // 表格的高度
      dataScopeOptions: [], // 角色数据范围选择项
      filters: {
        roleName: ''
      },
      columns: [], // 初始表头信息
      pageRequest: { pageNum: 1, pageSize: 10 }, // 分页请示参数信息, 当前页,每页大小
      pageResult: {}, // 分页查询结果集
      roleId: null, // 角色ID
      treeMenuData: [], // 菜单信息
      checkedKeys: [], // 选中的菜单信息
      defaultProps: {
        // 菜单树属性
        children: 'children',
        label: 'menuName'
      },
      roleInfo: {
        roleId: null,
        roleCode: '',
        roleName: '',
        dataScope: '0'
      },
      dataScopes: [], // 数据范围
      bizScopes: [
        { key: 'biz_site', value: '沼气站点管理' },
        { key: 'biz_light', value: '太阳能路灯管理' }
      ], // 控制业务范围
      dialogVisible: false, // 新增和修改页面窗口打开标识
      objectId: null // 对象主键ID,用户于修改时进行传递
    }
  },
  methods: {
    initLoad() {
      // 加载角色数据范围选择项
      this.$api.dict.findDictByType('sys_role_data_scope').then(res => {
        if (res.code === 200) {
          this.dataScopeOptions = res.data
        }
      })
    },
    /**
     * 分页查询数据
     * @param pageParams 分页请求参数
     */
    findPage: function(pageParams) {
      this.objectId = null
      if (pageParams !== null) {
        this.pageRequest = pageParams.pageRequest
      }
      this.pageRequest.Q_roleName_S_LK = this.filters.roleName
      this.$api.role
        .findPage(this.pageRequest)
        .then(res => {
          if (res.code === 200) {
            this.pageResult = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .then(pageParams != null ? pageParams.callback : '')
    },
    /***
     * 初始化列表显示
     */
    initColumns: function() {
      this.columns = [
        { prop: 'roleCode', label: '角色编码', sortable: false, minWidth: 100 },
        { prop: 'roleName', label: '角色名称', sortable: false, minWidth: 100 },
        {
          prop: 'roleDataScope',
          label: '数据权限',
          sortable: false,
          minWidth: 120,
          formatter: this.formatRoleDataScope
        },
        { prop: 'roleDesc', label: '角色描述', sortable: false, minWidth: 100 },
        {
          prop: 'formatStatus',
          label: '状态',
          sortable: false,
          minWidth: 70,
          slot: true
        },
        {
          prop: 'optSolt',
          label: '操作',
          sortable: false,
          slot: true,
          minWidth: 280
        }
      ]
    },
    handleAdd: function() {
      this.dialogVisible = true
      this.objectId = null
    },
    // 显示编辑界面
    handleEdit: function(params) {
      this.dialogVisible = true
      this.objectId = params.roleId
    },
    // 删除角色信息
    handleDelete: function(params) {
      this.$confirm('确认删除选中记录吗？？', '提示', {}).then(() => {
        this.$api.role.deleteObj(params.roleId).then(res => {
          if (res.code == 200) {
            this.$message.success('删除成功！')
            this.findPage(null)
          } else {
            this.$message.error('删除失败,' + res.msg)
          }
        })
      })
    },
    // 设置菜单访问权限
    handleMenuPermission: function(row) {
      this.treeMenuData = []
      this.checkedKeys = []
      //查询选中的菜单信息
      this.$api.role.findRoleMenus(row.roleId).then(res => {
        let menuList = res.data
        for (let i = 0; i < menuList.length; i++) {
          this.checkedKeys.push(menuList[i].menuId)
        }
      })
      this.$api.menu.findMenuTree().then(res => {
        this.treeMenuData = res.data
        this.roleId = row.roleId
        this.dialogMenuPermsVisible = true
      })
    },
    // 设置数据访问权限
    handleDataPermission: function(row) {
      this.dialogDataPermsVisible = true
      this.objectId = row.roleId
    },
    submitMenuPerms: function(roleId) {
      let menuIds = this.$refs.menuTree.getCheckedKeys().join(',')
      this.$api.role.saveRoleMenus(roleId, menuIds).then(res => {
        if (res.code == 200) {
          this.$message.success('操作成功！')
          this.dialogMenuPermsVisible = false
        } else {
          this.$message.error('操作失败,' + res.msg)
        }
      })
    },
    formatRoleDataScope: function(row) {
      let name = this.dataScopeOptions.map(obj => {
        if (obj.dictValue == row.dataScope) {
          return obj.dictLabel
        } else {
          return ''
        }
      })
      return name
    },
    // 设置可视区域高度
    initHeight: function() {
      let _this = this
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          _this.tableHeight = window.innerHeight - 220
        })()
      }
    }
  },
  mounted() {
    this.initHeight()
    this.initColumns()
    this.initLoad()
  },
  filters: {
    /**
     * 状态格式化
     * @param status 当前显示状态
     * @param type 1 获取内容，2 获得颜色
     * @returns {string|*} 格式化后结果
     */
    statusFilter(status, type) {
      const statusMap = {
        1: ['正常', 'success'],
        0: ['禁用', 'danger']
      }
      if (1 == type) {
        return statusMap[status][0]
      } else {
        return statusMap[status][1]
      }
    }
  }
}
</script>

<style>
/* 顶部下划线 */
.dialog-mask .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
</style>
