<template>
  <el-form
    :model="form"
    :rules="dataRule"
    ref="dataForm"
    @keyup.enter.native="submitForm()"
    label-width="80px"
    size="small"
    style="text-align:left;"
  >
    <el-form-item label="角色编码" prop="roleCode">
      <el-input
        v-model="form.roleCode"
        placeholder="由数字和字母组成"
      ></el-input>
    </el-form-item>
    <el-form-item label="角色名称" prop="roleName">
      <el-input v-model="form.roleName" placeholder="请输入中文名称"></el-input>
    </el-form-item>
    <el-form-item label="角色描述" prop="phone">
      <el-input type="textarea" v-model="form.roleDesc"></el-input>
    </el-form-item>
    <el-form-item label="状态" prop="status">
      <el-radio-group v-model="form.status">
        <el-radio border label="1">正常</el-radio>
        <el-radio border label="0">禁用</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button @click="closeWindow">取消</el-button>
      <el-button type="primary" @click="submitForm" :loading="editLoading"
        >提交</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'dataForm',
  props: {
    objectId: Number // 在子组件中通过props接收编辑的对象主键
  },
  data() {
    return {
      operation: true, // true:新增, false:编辑
      form: {
        roleId: null,
        roleCode: '',
        roleName: '',
        roleDesc: '',
        status: '1'
      },
      dataRule: {
        roleCode: [
          { required: true, message: '角色编码不能为空', trigger: 'blur' }
        ],
        roleName: [
          { required: true, message: '角色编码不能为空', trigger: 'blur' }
        ]
      },
      editLoading: false
    }
  },
  watch: {
    // 通过watch函数监控objectId的变化，把值copy到本地的对象
    objectId: {
      immediate: true, // 很重要！！！ 开启首次赋值监听
      deep: true,
      handler(val) {
        if (val == null) {
          this.resetForm()
          return
        }
        this.findObj(val)
      }
    }
  },
  methods: {
    // 表单提交
    submitForm: function() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          // 角色ID使用逗号隔开
          this.editLoading = true
          this.$api.role
            .save(this.form, this.operation ? 'post' : 'put')
            .then(res => {
              this.editLoading = false
              if (res.code == 200) {
                this.$message.success('操作成功！')
                this.$emit('reload') //列表信息
                this.closeWindow()
              } else {
                this.$message.error('操作失败,' + res.msg)
              }
            })
        }
      })
    },
    /**
     * 根据对象ID查询对象信息
     * @param id
     */
    findObj: function(id) {
      // 不显示密码框
      this.operation = false
      this.$api.role.getObj(id).then(res => {
        if (res.code == 200) {
          this.form = Object.assign({}, res.data)
          this.form.status = this.form.status + ''
        } else {
          this.$message.error('查询失败,' + res.msg)
        }
      })
    },
    // 关闭新增修改窗口
    closeWindow: function() {
      this.resetForm()
      this.$emit('close') // 子组件通过`this.$emit`调用父组件的函数
    },
    // 重置表单信息
    resetForm: function() {
      this.form = {
        roleId: null,
        roleCode: '',
        roleName: '',
        roleDesc: '',
        status: '1'
      }
    }
  }
}
</script>

<style scoped></style>
