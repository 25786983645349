<template>
  <div class="page-container">
    <!--工具栏-->
    <div class="toolbar" style="float:left;padding-top:10px;padding-left:15px;">
      <el-form
        :inline="true"
        :model="filters"
        :size="size"
        style="text-align:left;"
      >
        <el-form-item>
          <el-input
            v-model="filters.projectName"
            placeholder="项目名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="cityValue"
            placeholder="请选择市"
            @change="chooseCity"
            style="width: 150px;"
            :disabled="cityDisabled"
          >
            <el-option
              v-for="item in cityList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="countyValue"
            placeholder="请选择区/县"
            @change="chooseCounty"
            :disabled="countyDisabled"
          >
            <el-option
              v-for="item in countyList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="streetValue"
            placeholder="请选择乡/镇"
            @change="chooseStreet"
            style="width: 150px;"
            :disabled="streetDisabled"
          >
            <el-option
              v-for="item in streetList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="villageValue"
            placeholder="请选择村"
            @change="chooseVillage"
            style="width: 150px;"
          >
            <el-option
              v-for="item in villageList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="filters.tun"
            placeholder="屯名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="filters.verName"
            placeholder="核验人姓名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="filters.useStatus"
            style="width: 160px"
            clearable
            placeholder="沼气设施核验情况"
          >
            <el-option
              v-for="item in useOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-search"
            label="查询"
            type="primary"
            @click="findPage(null)"
          />
        </el-form-item>
        <el-form-item>
          <base-button
            icon="fa fa-share-square-o"
            label="批量审核"
            type="success"
            perms="biogas:ver:approve"
            @click="handleBatchApprove"
          />
        </el-form-item>
      </el-form>
    </div>
    <!--表格内容栏-->
    <base-table
      :data="pageResult"
      :columns="columns"
      :maxHeight="tableHeight"
      :showOperation="showOperation"
      :showSelection="showSelection"
      @selectionChange="selectionChange"
      @findPage="findPage"
    >
      <!--状态格式化 slot="formatStatus" 要与下列的表格prop 设置的值一致-->
      <template slot="formatVerStatus" slot-scope="scope">
        <el-tag :type="scope.row.verStatus | statusFilter(2)">{{
          scope.row.verStatus | statusFilter(1)
        }}</el-tag>
      </template>
      <template slot="formatDataSource" slot-scope="scope">
        <el-tag :type="scope.row.dataSource | sourceFilter(2)">{{
          scope.row.dataSource | sourceFilter(1)
        }}</el-tag>
      </template>
      <template slot="formatUse" slot-scope="scope">
        <el-tag :style="scope.row | useFilter(2)">{{
          scope.row | useFilter(1)
        }}</el-tag>
      </template>
      <template slot="optSolt" slot-scope="scope">
        <base-button
          icon="fa fa-address-card"
          label="查看"
          size="mini"
          type="primary"
          perms="biogas:ver:view"
          @click="handleView(scope.row)"
        />
        <base-button
          icon="fa fa-binoculars"
          label="审核"
          size="mini"
          type="primary"
          perms="biogas:ver:approve"
          @click="handleApprove(scope.row)"
        />
      </template>
    </base-table>
    <!-- 核验项目审核界面 -->
    <el-dialog
      title="沼气工程核验项目审核"
      width="70%"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <site-approve-form
        :objectId="objectId"
        @close="dialogVisible = false"
        @reload="findPage(null)"
      />
    </el-dialog>
    <el-dialog
      title="沼气工程核验项目详细信息"
      width="70%"
      :visible.sync="dialogViewVisible"
      :close-on-click-modal="false"
      custom-class="dialog-mask"
    >
      <!-- 表单 -->
      <site-view :objectId="objectId" @close="dialogViewVisible = false" />
    </el-dialog>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable' // 树型列表
import BaseButton from '@/components/BaseButton' // 公共按钮
import SiteApproveForm from './SiteApproveForm'
import SiteView from './SiteView'
import Cookies from 'js-cookie'
let _this = null // 定义一个全局能调用的this
export default {
  name: 'siteUnApprove',
  components: {
    BaseTable,
    BaseButton,
    SiteApproveForm,
    SiteView
  },
  data() {
    return {
      size: 'small', // 样式图标大小
      showOperation: false, // 不显示模板的操作状态栏
      cityList: [], // 市列表
      countyList: [], // 区/县列表
      streetList: [], // 乡/镇列表
      villageList: [], // 村列表
      cityValue: '', // 选中的市
      countyValue: '', // 选中的区县
      streetValue: '', // 选中的乡/镇
      villageValue: '', // 选中的村
      cityDisabled: false, //市下拉框禁止选择
      countyDisabled: false, //市下拉框禁止选择
      streetDisabled: false, //市下拉框禁止选择
      tableHeight: window.innerHeight - 220, // 表格的高度
      showSelection: true,
      typeList: [], // 项目建设来源
      verStatusOptions: [
        { value: 1, label: '未核验', color: 'danger' },
        { value: 2, label: '审核中', color: 'warning' },
        { value: 3, label: '已归档', color: 'success' }
      ], // 核验状态
      dataSourceOptions: [
        { value: 1, label: '档案数据', color: 'success' },
        { value: 2, label: '核验新增', color: 'warning' }
      ], // 数据来源
      useOptions: [
        { value: -1, label: '待查项目', color: '#CD99C9' },
        { value: 1, label: '正常(间歇)使用', color: '#1DAB78' },
        { value: 2, label: '停用', color: '#46bce5' },
        { value: 3, label: '报废', color: '#AD3E35' },
        { value: 4, label: '事实报废', color: '#99AD25' },
        { value: 5, label: '待报废', color: '#9224AD' }
      ], // 使用情况
      filters: {
        projectName: '',
        verName: '',
        tun: '',
        useStatus: null,
        isPending: null
      },
      columns: [], // 初始表头信息
      pageRequest: { pageNum: 1, pageSize: 10 }, // 分页请示参数信息, 当前页,每页大小
      pageResult: {}, // 分页查询结果集
      dialogVisible: false, // 新增和修改页面窗口打开标识
      dialogViewVisible: false, //查看详细页面窗口打开标识
      objectId: null, // 对象主键ID,用户于修改时进行传递
      checkRows: [] // 复选框选中的行
    }
  },
  methods: {
    initLoad() {
      // 加载项目建设来源
      this.$api.dict.findDictByType('site_project_type').then(res => {
        if (res.code === 200) {
          this.typeList = res.data
        }
      })
      // 加载机构下拉框
      this.$api.org.getOrgByPid(1).then(resp => {
        if (resp.code === 200) {
          this.cityList = resp.data
        } else {
          this.$message.error(resp.msg)
        }
      })
    },
    /**
     * 分页查询数据
     * @param pageParams 分页请求参数
     */
    findPage: function(pageParams) {
      if (pageParams !== null) {
        this.pageRequest = pageParams.pageRequest
      }
      this.pageRequest.projectName = this.filters.projectName
      this.pageRequest.biogasType = 2
      this.pageRequest.verStatus = 2
      this.pageRequest.orgId = this.filters.orgId
      this.pageRequest.tun = this.filters.tun
      this.pageRequest.useStatus = this.filters.useStatus
      this.pageRequest.verName = this.filters.verName
      this.$api.biogas
        .findVerPage(this.pageRequest)
        .then(res => {
          if (res.code === 200) {
            this.pageResult = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .then(pageParams != null ? pageParams.callback : '')
    },
    /***
     * 初始化列表显示
     */
    initColumns: function() {
      this.columns = [
        {
          prop: 'projectName',
          label: '项目名称',
          sortable: false,
          minWidth: 160
        },
        { prop: 'address', label: '详细地址', sortable: false, minWidth: 160 },
        {
          prop: 'projectType',
          label: '项目类型',
          sortable: false,
          minWidth: 100,
          formatter: this.formatType
        },
        {
          prop: 'formatDataSource',
          label: '数据来源',
          sortable: false,
          slot: true,
          minWidth: 100
        },
        {
          prop: 'formatUse',
          label: '使用情况',
          sortable: false,
          minWidth: 140,
          slot: true
        },
        {
          prop: 'batchId',
          label: '核验年度',
          sortable: false,
          minWidth: 80
        },
        {
          prop: 'projectVer.verName',
          label: '核验人',
          sortable: false,
          minWidth: 80
        },
        {
          prop: 'verTime',
          label: '核验提交时间',
          sortable: false,
          minWidth: 120
        },
        {
          prop: 'optSolt',
          label: '操作',
          sortable: false,
          slot: true,
          minWidth: 80
        }
      ]
    },
    handleAdd: function() {},
    // 设置可视区域高度
    initHeight: function() {
      window.onresize = () => {
        return (() => {
          // 获得当前屏幕的高度
          _this.tableHeight = window.innerHeight - 220
        })()
      }
    },
    formatType(row) {
      let typeStr = ''
      this.typeList.forEach(item => {
        if (item.dictValue == String(row.projectType)) {
          typeStr = item.dictLabel
        }
      })
      return typeStr
    },
    //显示审核页面
    handleApprove(row) {
      this.dialogVisible = true
      this.objectId = row.projectId
    },
    //显示详细信息
    handleView(row) {
      this.dialogViewVisible = true
      this.objectId = row.projectId
    },
    // 复选框选中事件
    selectionChange(params) {
      this.checkRows = params.selections
    },
    //批量审核
    handleBatchApprove() {
      // 获得要审核审核id,使用逗号隔开
      let ids = this.checkRows.map(item => item.projectVer.verId).toString()
      if (ids == '' || ids == null) {
        this.$message.error('亲，请选择要批量审核的核验项目信息!')
      }
      let paramObj = {}
      paramObj.verIds = ids
      this.$api.biogas.batchApprove(paramObj).then(res => {
        if (res.code == 200) {
          this.$message.success('批量审核成功！')
          this.findPage(null)
        } else {
          this.$message.error('批量审核失败,' + res.msg)
        }
      })
    },
    /**选中市**/
    chooseCity: function(value) {
      this.countyList = []
      this.countyValue = ''
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
      this.filters.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.countyList = resp.data
        }
      })
    },
    /**选中区县**/
    chooseCounty: function(value) {
      this.streetList = []
      this.streetValue = ''
      this.villageList = []
      this.villageValue = ''
      this.filters.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.streetList = resp.data
        }
      })
    },
    /**选中乡/镇**/
    chooseStreet: function(value) {
      this.villageList = []
      this.villageValue = ''
      this.filters.orgId = value
      this.$api.org.getOrgByPid(value).then(resp => {
        if (resp.code === 200) {
          this.villageList = resp.data
        }
      })
    },
    /**选中村**/
    chooseVillage: function(value) {
      this.filters.orgId = value
    },
    // 初始化区域选中信息
    initSelectOrg() {
      // 获取当前登录用户的机构信息
      // 因为区域选择框是市级开始，而且路径等是从 省级->市级->区级->乡级->村级
      let orgPath = Cookies.get('user_orgIds')
      // 分割路径ID
      let orgIds = orgPath.split(',')
      if (orgIds.length === 2) {
        // 当前账号到市级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.cityDisabled = true
      }
      // 当前账号到县级
      if (orgIds.length === 3) {
        // 只到区级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[2])
        this.chooseCounty(this.countyValue)
        this.cityDisabled = true
        this.countyDisabled = true
      }
      // 当前账号的机构到乡镇级
      if (orgIds.length === 4) {
        // 只到乡级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[2])
        this.chooseCounty(this.countyValue)
        this.streetValue = parseInt(orgIds[3])
        this.chooseStreet(this.streetValue)
        this.cityDisabled = true
        this.countyDisabled = true
        this.streetDisabled = true
      }
      if (orgIds.length === 5) {
        // 村级
        this.cityValue = parseInt(orgIds[1])
        this.chooseCity(this.cityValue)
        this.countyValue = parseInt(orgIds[2])
        this.chooseCounty(this.countyValue)
        this.streetValue = parseInt(orgIds[3])
        this.chooseStreet(this.streetValue)
        this.villageValue = parseInt(orgIds[4])
        this.cityDisabled = true
        this.countyDisabled = true
        this.streetDisabled = true
      }
    }
  },
  mounted() {
    _this = this
    this.initHeight()
    this.initColumns()
  },
  created() {
    this.initLoad()
    this.initSelectOrg()
  },
  filters: {
    /**
     * 状态格式化
     * @param status 当前显示状态
     * @param type 1 获取内容，2 获得颜色
     * @returns {string|*} 格式化后结果
     */
    statusFilter(status, type) {
      for (let i = 0; i < _this.verStatusOptions.length; i++) {
        if (status == _this.verStatusOptions[i].value) {
          if (1 == type) {
            return _this.verStatusOptions[i].label
          } else {
            return _this.verStatusOptions[i].color
          }
        }
      }
    },
    sourceFilter(status, type) {
      for (let i = 0; i < _this.dataSourceOptions.length; i++) {
        if (status == _this.dataSourceOptions[i].value) {
          if (1 == type) {
            return _this.dataSourceOptions[i].label
          } else {
            return _this.dataSourceOptions[i].color
          }
        }
      }
    },
    useFilter(row, type) {
      let status = row.projectVer.useStatus
      if (row.projectVer.isPending === 1) {
        status = -1
      }
      for (let i = 0; i < _this.useOptions.length; i++) {
        if (status == _this.useOptions[i].value) {
          if (1 == type) {
            return _this.useOptions[i].label
          } else {
            return 'color: ' + _this.useOptions[i].color + ';'
          }
        }
      }
    }
  }
}
</script>

<style>
/* 顶部下划线 */
.dialog-mask .el-dialog__header {
  border-bottom: 1px solid #ccc;
}
.dialog-mask .el-dialog__body {
  padding: 10px 20px;
}
</style>
